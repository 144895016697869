import { NetworkType } from '@multiversx/sdk-dapp/types';
import { DelegationContractType } from 'core';
import { ExchangeToken } from 'utils/types';

export const nftMinterContract = 'erd1qqqqqqqqqqqqqpgqupvwtktve04lunnz0x2tafx327w4n7h08alsu9xrvt'
export const egldWrapperContract: string = 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3'
export const nftAttributesContract = 'erd1qqqqqqqqqqqqqpgqmrdzqz3utcx4hcssaxswzrm0k5urnjmmwe0sndvyhm'
export const airdropContract: string = 'erd1qqqqqqqqqqqqqpgqkg84sqdc3za05ce3r4nqqdpeqzx429k4we0s8wp0u2'

export const kroTicker = 'KRO-df97ec';
export const lkkroTicker = 'LKKRO-e6ef92';
export const kroganNFT = 'KROGAN-54c361';

export const resetPrice = '12000000000000000000000';
export const pointsPrice: {[key: string]: string} = {
	'10': '10000000000000000000000',
	'25': '20000000000000000000000',
	'50': '35000000000000000000000'
};

export const kroganApi = ["localhost", "192.168.8.100"].includes(location.hostname) ? 'http://localhost:1370' : 'https://api.kroganswap.com';
export const gateway = 'https://gateway.multiversx.com';

export const network: NetworkType = {
	id: 'mainnet',
	chainId: '1',
	name: 'Mainnet',
	egldLabel: 'EGLD',
	decimals: '18',
	digits: '2',
	gasPerDataByte: '1500',
	walletConnectDeepLink: 'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://xportal.com/',
	walletAddress: 'https://wallet.multiversx.com/dapp/init',
	walletConnectBridgeAddresses: ['https://bridge.walletconnect.org'],
	walletConnectV2RelayAddresses: ['wss://relay.walletconnect.com'],
	apiAddress: 'https://elrond-api.blastapi.io/29ccfaea-c188-4ab0-9257-85a6310f996d',
	explorerAddress: 'http://explorer.multiversx.com',
	apiTimeout: '4000',
	walletConnectV2ProjectId: 'dd783f271f7935e443f9df1b1442ac70', // or e429ce46a9c011b5cdc17eb357a40ee2
};

export const exchangeTokens: ExchangeToken[] = [
	{ identifier: 'EGLD', decimals: 18 },
	{ identifier: 'KRO-df97ec', decimals: 18, intermediate: 'USDC-c76f1f' },
	{ identifier: 'USDC-c76f1f', decimals: 6 }
]

export const delegationContractData: DelegationContractType[] = [
	{
		name: 'ESDTNFTTransfer',
		gasLimit: 30000000,
	}, {
		name: 'ESDTTransfer',
		gasLimit: 30000000,
	}, {
		name: 'enterPrivateSale',
		gasLimit: 30000000,
	}, {
		name: 'enterSale',
		gasLimit: 30000000,
	}, {
		name: 'withdraw',
		gasLimit: 30000000,
	}, {
		name: 'ClaimDeveloperRewards',
		gasLimit: 10000000,
	}, {
		name: 'ESDTLocalBurn',
		gasLimit: 2000000,
	}, {
		name: 'setSpecialRole',
		gasLimit: 100000000,
	}, {
		name: 'createCategories',
		gasLimit: 10000000,
	}, {
		name: 'claimRewards',
		gasLimit: 20000000,
	}
];
