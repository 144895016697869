import { 
	AbiRegistry, Address, Interaction, ResultsParser, 
	SmartContract, TypedOutcomeBundle 
} from "@multiversx/sdk-core/out"
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out"
import { ESDTToken } from "core"
import { StakingPool, UserStakingPool, UserWithdrawData } from "utils/types"


export class TokenStakingContract {
	
	private smartContract?: SmartContract
	private contractAddress: string
	private readonly proxyProvider: ProxyNetworkProvider

	constructor(
		contractAddress: string,
		proxyProvider: ProxyNetworkProvider
	) {
		this.proxyProvider = proxyProvider
		this.contractAddress = contractAddress
	}

	private async getContract(): Promise<SmartContract> {
		if (this.smartContract) return this.smartContract

		let response = await fetch('/contract/token-staking.abi.json').then(res => res.json())
		let abi = AbiRegistry.create(response)
		this.smartContract = new SmartContract({ address: new Address(this.contractAddress), abi: abi })
		
		return this.smartContract
	}

	private async runInteraction(interaction: Interaction): Promise<TypedOutcomeBundle> {
		const query = interaction.check().buildQuery()
		const queryResponse = await this.proxyProvider.queryContract(query)

		const resultsParser = new ResultsParser()
		const endpointDefinition = interaction.getEndpoint()
		return resultsParser.parseQueryResponse(queryResponse, endpointDefinition)
	}

	async getStakingPool(): Promise<StakingPool | null> {
		let smartContract = await this.getContract()
		let interaction = <Interaction>smartContract.methods.currentAprData()

		const result = await this.runInteraction(interaction)
		console.log(result)
		return {
			rewardRate: result.values[0].valueOf().toString(),
			totalSupply: result.values[1].valueOf().toString(),
			periodFinish: result.values[2].valueOf().toString(),
			allowHarvest: result.values[3].valueOf(),
		}
	}

	async getUserStakingPool(address: string): Promise<UserStakingPool | null> {
		let smartContract = await this.getContract()
		let interaction = <Interaction>smartContract.methods.currentUserData([new Address(address)])

		const result = await this.runInteraction(interaction)
		return {
			rewardRate: result.values[0].valueOf().toString(),
			totalSupply: result.values[1].valueOf().toString(),
			periodFinish: result.values[2].valueOf().toString(),
			allowHarvest: result.values[3].valueOf(),
			earned: result.values[4].valueOf().toString(),
			userBalance: result.values[5].valueOf().toString()
		}
	}

	async getUserData(address: string): Promise<UserWithdrawData | null> {
		let smartContract = await this.getContract()
		let interaction = <Interaction>smartContract.methods.userWithdrawData([new Address(address)])

		const result = await this.runInteraction(interaction)
		return {
			earned: result.values[0].valueOf().toString(),
			userTokens: result.values[1].valueOf().toString(),
			penaltyUntil: result.values[2].valueOf().toNumber(),
			penalty: result.values[3].valueOf().toNumber(),
			allowHarvest: result.values[4].valueOf()
		}
	}

	async getUserLockedTokens(address: string): Promise<ESDTToken[]> {
		let smartContract = await this.getContract()
		let interaction = <Interaction>smartContract.methods.getUserLockedTokens([new Address(address)])

		const result = await this.runInteraction(interaction)
		return result.firstValue?.valueOf()
			.map((item: any) => {
				let token = item
				return {
					identifier: token.token_identifier.toString(),
					name: '',
					ticker: '',
					nonce: token.token_nonce,
					decimals: 18,
					balance: token.amount.toString(),
				}
			})
	}

	async getBurnedTokens(): Promise<string> {
		let smartContract = await this.getContract()
		let interaction = <Interaction>smartContract.methods.getBurned()

		const result = await this.runInteraction(interaction)
		let object: any = result.firstValue?.valueOf()
		return object.toString()
	}

	async getUnboundPeriod(address: string): Promise<number | undefined> {
		let smartContract = await this.getContract()
		let interaction = <Interaction>smartContract.methods
			.getUserUnboundPeriod([new Address(address)])

		const result = await this.runInteraction(interaction)
		let object: any = result.firstValue?.valueOf()
		return object?.toNumber()
	}


}