import { kroganApi } from 'config';
import { Earning, Referral, User } from 'utils/types';

export const accountService = {
	fetchUser: (address: string): Promise<User> => {
		return fetch(`${kroganApi}/account/account?address=${address}`)
			.then(res => res.json())
			.catch(err => { console.log(err) })
	},
	fetchAirdrops: (identifier: string, start: number): Promise<Earning[]> => {
		return fetch(`${kroganApi}/account/airdrops?identifier=${identifier}&start=${start}`)
				.then(res => res.json())
				.catch(err => { console.log(err) })
	},
	fetchUserEarnings: (address: string): Promise<Earning[]> => {
		return fetch(`${kroganApi}/account/signedEarnings?address=${address}`)
			.then(res => res.json())
			.catch(err => { console.log(err) })
	},
	linkDiscord: (discord: string, address: string, verification: number) => {
		fetch(`${kroganApi}/account/connectDiscord?address=${address}&discordId=${discord}&verification=${verification}`)
			.then(res => res.json())
			.then(result => console.log(result))
			.catch(err => {
				console.error(err)
			})
	},
	registerReferral: (address: string): Promise<Referral | undefined> => {
		return fetch(`${kroganApi}/account/registerReferral?address=${address}`)
			.then(res => res.json())
			.catch(err => { console.log(err) })
	},
	fetchReferral: (hash: string): Promise<string | undefined> => {
		return fetch(`${kroganApi}/account/getReferral?hash=${hash}`)
			.then(res => res.text())
			.catch(err => { 
				console.log(err) 
				return undefined
			})
	},
	tokenPrice: (id: string): Promise<number> => {
		let tokenId = id == 'EGLD' ? 'WEGLD-bd4d79' : id
		return fetch('https://graph.xexchange.com/graphql', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify({query: `{ getTokenPriceUSD(tokenID:"${tokenId}")}`})
		})
		.then(r => r.json())
		.then(r => r.data.getTokenPriceUSD)
		.catch(err => { console.log(err) })
	},
}