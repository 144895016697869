import 'utils/extensions'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import Menu, { MenuType } from 'views/components/Menu'
import { nftDetails } from 'services/blockchain'
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import { NFTView } from 'views/components/NFT/NFTView';
import { nominateNumberToHex, useAccount, useCoreContext } from 'core'
import { LineButton } from 'views/components/uielements/LineButton'
import { ConnectButtonContent } from 'core/components/ConnectButtonContent'
import useAnalyticsEventTracker from 'hooks/useAnalyticsEvents'
import { NftModel } from 'models/NftModel'
import { kroganNFT } from 'config'
import { PurchasePoints } from 'views/components/Others/PurchasePoints'
import { contractViews } from 'contracts/ContractViews'


const RaritySection = ({
	alt,
}: {
	alt: boolean;
}): React.ReactElement => {
	
	const { proxy } = useAccount()

	const { getNfts, getCollection, getNft } = nftDetails
	const [searchResult, setSearchResult] = React.useState([] as NftModel[])
	const [nfts, setNfts] = React.useState({} as { [key: string]: NftModel[] })
	const [collection, setCollection] = React.useState({} as {string: any})
	const [loading, setLoading] = React.useState(true)
	const [inputValue, setInputValue] = React.useState('')
	const [error, setError] = React.useState('')
	const [points, setPoints] = React.useState(0)

	const { isLoggedIn, address } = useCoreContext()
	const gaEventTracker = useAnalyticsEventTracker('Rarity')

	const loadPoints = () => {
		contractViews.getPurchasedPoints(proxy(), address)
			.then(value => {
				if (value) setPoints(value)
			})
	}

	const fetchNFTs = () => {
		if (!isLoggedIn) return

		loadPoints()
		getNfts(address)
			.then((value: NftModel[]) => {
				setLoading(false)
				if (!value) return

				let result: { [key: string]: NftModel[] } = {}
				value.forEach(nft => {
					if (result[nft.level]) {
						result[nft.level].push(nft)
					} else {
						result[nft.level] = [nft]
					}
				})
				setNfts(result)
			})
			.catch(e => console.error('getMintCategories error', e))
	}
	useEffect(fetchNFTs, [isLoggedIn])

	const fetchCollection = () => {
		getCollection()
			.then((value: {string: any}) => {
				setCollection(value)
			})
			.catch(e => console.error('getMintCategories error', e))
	}
	useEffect(fetchCollection, [])

	const searchNft = (e: any) => {
		e.preventDefault()

		const regex = new RegExp(`${kroganNFT}-[0-9a-z]+`, 'g')
		const found = inputValue.match(regex)
		if (found) {
			getNFTById(inputValue.trimStart().trimEnd())
		} else {
			const regex = /.*#?[0-9]+/g;
			const found = inputValue.match(regex);
			if (found && found.length > 0) {
				let value = found[0]
				let nonce = parseInt(value.substring(value.indexOf('#')+1, value.length))
				var nonceHex = nominateNumberToHex('' + nonce)
				if (nonceHex.length % 2 == 1) {
					nonceHex = '0' + nonceHex
				}
				getNFTById(`${kroganNFT}-${nonceHex}`)
			} else {
				setError('Invalid search. Try searching by NFT name or token identifier(eg: KROGAN-54c361-<nonce>).')
			}
		}
	}

	const getNFTById = (id: string) => {
		gaEventTracker('RaritySearch')
		console.log(`Search for #${id}`)
		getNft(id).then(esdt => {
			setSearchResult([new NftModel(esdt)])
			setLoading(false)
			setError('')
		})
	}

	const onChangeInput = (e: any) => {
		setInputValue(e.target.value)
	}

	return (
		<>
			<Row className={classNames({
				section: true,
				"no-padding": true,
				"row-header": true,
				alt: alt,
				"mb-3": true
			})}>
				<Menu menuType={MenuType.Other} />

				<Col md={6} className="section--header">
					<h2 className="mb-1">Your Spaceships</h2>
					<p>Krogan Rarity Tool</p>
					<p className="mt-3">Visual traits of NFTs define rarity. The higher the rarity score, the more valuable.</p>
					<p className="mt-1">The Level of the spaceship reflects the dynamic attributes. &nbsp;
					<a href="https://whitepaper.krogancoin.com/components/krogan-nfts#dynamic-attributes" target="_blank" rel="noreferrer">Read more</a>.</p>
				</Col>
				<Col md={6} className="right-side">
					<div className="right-section mb-4">
						<LineButton type="primary" style={{ marginRight: '3rem' }}>
							<ConnectButtonContent />
						</LineButton>
					</div>
				</Col>
			</Row>
			<Row className={classNames({
				"section--continue": true,
				section: true,
				alt: alt
			})}>

				{searchResult.length > 0 &&
					<Row>
						<h3 className="center">Search Result</h3>
						
						{searchResult.map((nft, j) => (
							<Col key={j} md={12} lg={{ offset: 3, span: 6 }} xl={{ offset: 4, span: 4 }} >
								<NFTView index={j} esdt={nft} collection={collection} reload={fetchNFTs} />
							</Col>
						))}
					</Row>
				}
				<p className="center mb-1">Search by Token name or identifier</p>
				<Form onSubmit={searchNft}>
					<InputGroup className="mb-2">
						<FormControl
							type="text"
							value={inputValue}
							onChange={onChangeInput}
							name="search"
							placeholder="Krogan Spaceship #9 or KROGAN-54c361-09"
							aria-label="Search nft"
							aria-describedby="basic-addon2"
						/>
						<i></i>									
						<Button type="submit" variant="outline-secondary" className="text-action">Search</Button>
					</InputGroup>
				</Form>
				<p className="center mb-5 colored">{error}</p>
				
				{isLoggedIn == false ? (
					<Col sm="12" className="py-5">
						<p className="center">Connect your wallet to see your Krogan NFTs.</p>
					</Col>
				) : 
					loading ? (
						<Col sm="12" className="py-5 center">
							<p>Loading...</p>
						</Col>
					) : 
						Object.keys(nfts).length == 0 ? (
							<Col sm="12" className="py-5 center">
								<p>You do not own any Krogan NFTs.</p>
								<a href="https://kroganswap.com/collection/KROGAN-54c361">Purchase one here.</a>
							</Col>
						) : (
							<div style={{ maxWidth: 520, margin: '0 auto' }}>
								<h2 className='center'>Upgrade your spaceships</h2>
								<p className='center'>
									Every spaceship accumulates <span className='colored'>free</span> upgrade points <span className='colored'>every week</span>. 
									You can use them to shape your spaceship to match your play style.
									<br />
									<a href="https://whitepaper.krogancoin.com/components/krogan-nfts#upgrades" target="_blank" rel="noreferrer">Read more</a>.
								</p>
								<PurchasePoints points={points} reload={loadPoints} />
							</div>
						)
				}

				{Object.keys(nfts).reverse().map((level, i) => ( 
					<Row key={i} className='block-center'>
						<h3>Level {level}</h3>
						
						{nfts[level].map((nft, j) => (
							<Col key={j} md={12} lg={6} xxl={4} >
								<NFTView 
									index={j} 
									esdt={nft} 
									collection={collection} 
									reload={fetchNFTs} 
									extraPoints={points}
									upgradeable 
								/>
							</Col>
						))}
					</Row>
				))}
				
			</Row>
		</>
	);
}

export default RaritySection;