
export interface AttributeConfig {
	max: number,
	step: number,
	start: number,
	levelInc: number,
	randomSize: number,
	decrease: boolean
}

export enum AttributeKey {
	Speed = 'speed',
	Acceleration = 'acceleration',
	Evasion = 'evasion',
	Attack = 'attack',
	Accuracy = 'accuracy',
	FireRate = 'fireRate',
	Armor = 'armor',
	Health = 'health',
	Cargo = 'cargo',
	Mining = 'mining'
}

export const attributeConfig = (key: string): AttributeConfig => {

	const finalKey = key.toLowerCase()
		.replace('fire rate', 'fireRate')
		.replace('firerate', 'fireRate') as AttributeKey

	switch (finalKey) {

		case AttributeKey.Speed:
			return {
				max: 100,
				step: 2,
				start: 0,
				levelInc: 10,
				randomSize: 15,
				decrease: false
			}
		case AttributeKey.Acceleration:
			return {
				max: 50,
				step: 1,
				start: 55,
				levelInc: 5,
				randomSize: 7,
				decrease: true
			}
		case AttributeKey.Evasion:
			return {
				max: 40,
				step: 1,
				start: 55,
				levelInc: 5,
				randomSize: 7,
				decrease: true
			}
		case AttributeKey.Attack:
			return {
				max: 90,
				step: 1,
				start: 5,
				levelInc: 5,
				randomSize: 15,
				decrease: false
			}
		case AttributeKey.Accuracy:
			return {
				max: 45,
				step: 1,
				start: 45,
				levelInc: 0,
				randomSize: 10,
				decrease: false
			}
		case AttributeKey.FireRate:
			return {
				max: 90,
				step: 2,
				start: 5,
				levelInc: 0,
				randomSize: 10,
				decrease: false
			}
		case AttributeKey.Armor:
			return {
				max: 80,
				step: 1,
				start: 0,
				levelInc: 5,
				randomSize: 10,
				decrease: false
			}
		case AttributeKey.Health:
			return {
				max: 1620,
				step: 20,
				start: 220,
				levelInc: 40,
				randomSize: 40,
				decrease: false
			}
		case AttributeKey.Cargo:
			return {
				max: 80,
				step: 1,
				start: 5,
				levelInc: 10,
				randomSize: 10,
				decrease: false
			}
		case AttributeKey.Mining:
			return {
				max: 40,
				step: 1,
				start: 5,
				levelInc: 0,
				randomSize: 10,
				decrease: false
			}
	}
	
}