import { APIESDT, ESDTAttribute, ESDTMetaData } from "core";

export class NftModel {
	level: string;
	creator: string;
	name: string;
	nonce: string;
	royalties: string;
	collection: string;
	url: string;
	metadata: ESDTMetaData;
	dynamicAttributes: ESDTAttribute[];

	constructor(esdt: APIESDT) {
		const attrString = Buffer.from(esdt.attributes, 'base64').toString()
		const attrMap = attrString.split(';').map(pair => {
			const split = this.splitString(pair)
			return {
				trait_type: split[0],
				value: split[1]
			}
		})

		this.level = attrMap
			.filter(attr => ['level', 'Level'].includes(attr.trait_type))
			.first()?.value ?? '0'

		this.dynamicAttributes = attrMap
			.filter(attr => !['tags', 'Level', ''].includes(attr.trait_type))

		this.creator = esdt.creator
		this.name = esdt.name
		this.nonce = esdt.nonce
		this.royalties = esdt.royalties
		this.collection = esdt.collection
		this.url = Buffer.from(esdt.uris.first(), 'base64')
			.toString()
			.replace('gateway.ipfs.io/ipfs', 'krogancoin.com')
		this.metadata = esdt.metadata
	}

	getAttributeValue(key: string): ESDTAttribute {
		return this.dynamicAttributes.filter(attr => attr.trait_type == key).first()
	}

	isRegistered(): boolean {
		return this.dynamicAttributes.length > 2
	}

	splitString(string: string): string[] {
		const splitIndex = string.indexOf(":");
		const firstPart = string.substring(0, splitIndex);
		const secondPart = string.substring(splitIndex + 1);
		return [firstPart, secondPart];
	}
}