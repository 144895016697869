import React from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import HeaderSection from 'views/sections/Main/HeaderSection'

import NftStakingSection from 'views/sections/Nft/NftStakingSection'
import FooterSection from '../sections/Main/FooterSection'

const NftStaking = () => {

	return (
		<>
			<Container fluid>
				<HeaderSection 
					title='In-Wallet NFT Staking' 
					subtitle='No need to send your NFT into a contract. Hold at least 3 NFTs and earn rewards while you enjoy the game.'
				>
					<NftStakingSection />
				</HeaderSection>

				<FooterSection alt={true} />
			</Container>
		</>
	)
}

export default withRouter(NftStaking)