import moment from 'moment'
import BigNumber from 'bignumber.js'
import { network } from 'config'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faCaretDown, faCaretUp, faInfoCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { timestampToString } from 'utils/time'
import { StakingPool, StakingToken, UserStakingPool } from 'utils/types'
import { StakingActionType } from 'views/sections/Staking/StakingModal'
import { denominate, useAccount, useCoreContext } from 'core'
import { TokenStakingContract } from 'contracts/StakingContract'
import TransactionButton from '../Others/TransactionButton'
import { LineButton } from '../uielements/LineButton'


const StakingBoxView = ({
	title,
	tag,
	info,
	finished,
	stakingToken,
	onAction,	
}: {
	title: string;
	tag?: string;
	info: JSX.Element;
	finished?: boolean;
	onAction: (obj: { token: StakingToken, action: StakingActionType }) => void;
	stakingToken: StakingToken;
}): React.ReactElement => {

	const { address } = useCoreContext()
	const { proxy } = useAccount()

	const [detailsVisible, setDetailsVisible] = useState(false)
	const [burned, setBurned] = useState('0')
	const [pool, setPool] = useState(null as StakingPool | null)
	const [userPool, setUserPool] = useState(null as UserStakingPool | null)
	const [contract, setContract] = useState(null as TokenStakingContract | null)
	const [unbound, setUnbound] = useState(0)

	const init = () => {
		setContract(new TokenStakingContract(stakingToken.poolAddress, proxy()))
	}
	useEffect(init, [])


	const setAutoReload = () => {
		if (address == '') return
		const interval = setInterval(() => {
			getStakingPoolData()
		}, 10000)
		return () => clearInterval(interval);
	}
	useEffect(setAutoReload, [contract, address])

	const getStakingPoolData = () => {
		if (!contract) return
		if (address != '') {
			contract.getUserStakingPool(address).then(data => {
				setPool(null)
				setUserPool(data)
			})
		} else {
			contract.getStakingPool().then(data => {
				setUserPool(null)
				setPool(data)
			})
		}
	}
	useEffect(getStakingPoolData, [address, contract])

	const getUnbound = () => {
		if (address != '' && contract) {
			contract.getUnboundPeriod(address).then(data => {
				if (data) setUnbound(data)
			})
		}
	}
	useEffect(getUnbound, [address, contract])

	const getBurnedTokens = () => {
		if (!contract) return
		if (detailsVisible) {
			contract.getBurnedTokens().then(data => {
				setBurned(data)
			})
		}
	}
	useEffect(getBurnedTokens, [detailsVisible])

	const getPoolApr = (): string => {
		let data: StakingPool | null = pool ?? userPool
		if (data && data.rewardRate != '0') {
			return new BigNumber(data.rewardRate).multipliedBy(31556926).div(new BigNumber(data.totalSupply)).multipliedBy(100).toFixed(2)+'%'
		} else {
			return '0%'
		}
	}

	const convert = (value: string, decimals: number = 2, comma = true): string => {
		return denominate({
			input: value,
			denomination: 18,
			decimals: decimals,
			addCommas: comma
		})
	}

	const getPeriodFinish = () => {
		if (address == 'erd18h9sx7jp3w7z59eeq3f2an6wtzmwjz39rzxnd2gf7x8m3f2mwe0s2gdgr5') {
			if (userPool) {
				return <p className="mt-2">Add funds {moment(parseInt(userPool.periodFinish)*1000).fromNow()}</p>
			}
		}
		return <></>
	}
	
	return (
		<>
			<div className="staking gradient-box d-flex flex-column align-items-center justify-content-center">
				<div className="d-flex w-100 p-4">
					<div className="me-auto">
						<h2 className="mb-0 display-inline me-2">{title}</h2>
						<OverlayTrigger
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-verified`}>
									{info}
								</Tooltip>
							}>
							<FontAwesomeIcon icon={faInfoCircle} className="flag display-inline" />
						</OverlayTrigger>
						<p className="text-action">Earn {stakingToken.ticker} {tag && <span className="tag caption">{tag}</span>}</p>
					</div>
					<div>
						<img src="/images/icon-logo.svg" alt="Krogan Staking Pool" />
					</div>
				</div>
				<hr className="w-100" />

				{getPeriodFinish()}

				{pool && 
					<div className="px-4 pt-4 w-100">
						<div className="d-flex w-100 mb-2">
							<div className="me-auto">
								<p className="footnote">APR</p>
								<p className="h5">{getPoolApr()}</p>
							</div>
							<div>
								<p className="footnote">Total Staked KRO</p>
								<p className="h5 right">{convert(pool.totalSupply)}</p>
							</div>
						</div>
					</div>
				}

				{userPool &&
					<div className="px-4 pt-4 w-100">
						<div className="d-flex w-100 mb-2">
							<div className="me-auto">
								<p className="footnote">APR</p>
								<p className="h5">{getPoolApr()}</p>
							</div>
							<div>
								<p className="footnote">Total Staked KRO</p>
								<p className="h5 right">{convert(userPool.totalSupply)}</p>
							</div>
						</div>
						<div className="d-flex w-100 mb-2">
							<div className="me-auto">
								<p className="footnote">KRO Earned</p>
								<p className="h5 mb-0">{convert(userPool.earned, 2)}</p>
								<p className="h6 mb-0 colored">
									{(parseFloat(convert(userPool.earned, 5, false)) * stakingToken.price).toFixed(2)}$
								</p>
							</div>
							<div>
								<p className="footnote">My Staked KRO</p>
								<p className="h5 mb-0 right">{convert(userPool.userBalance)}</p>
								<p className="h6 mb-0 colored right">
									{(parseFloat(convert(userPool.userBalance, 5, false)) * stakingToken.price).toFixed(2)}$
								</p>
							</div>
						</div>

					</div>
				}

				{unbound > 0 ? 
					<div className="p-4 w-100">
						<div className="w-100">
							<TransactionButton className="w-100" onClick={() => onAction({ token: stakingToken, action: StakingActionType.UNBOUND })}>
								<LineButton small className="w-100">
									{timestampToString('Unbound in ', 'UNBOUND', unbound)}
								</LineButton>
							</TransactionButton>
						</div>
					</div>
				:
					!userPool || userPool.userBalance == '0' ? 
						<div className="p-4 w-100">
							<div className="w-100">
								<TransactionButton className="w-100" onClick={() => onAction({ token: stakingToken, action: StakingActionType.STAKE })}>
									<LineButton small className="w-100" disabled={finished == true}>STAKE</LineButton>
								</TransactionButton>
							</div>
						</div>
					: 
						<div className="p-4 w-100">
							<div className="center">
								<TransactionButton className="display-inline me-2" onClick={() => onAction({ token: stakingToken, action: StakingActionType.WITHDRAW })}>
									<LineButton small className="w-100">
										<FontAwesomeIcon icon={faMinus} className="secondary-icon" />
									</LineButton>
								</TransactionButton>
								<TransactionButton className="display-inline me-2" onClick={() => onAction({ token: stakingToken, action: StakingActionType.STAKE })}>
									<LineButton small disabled={finished == true} className="w-100">
										<FontAwesomeIcon icon={faPlus} className="secondary-icon" />
									</LineButton>
								</TransactionButton>
								<TransactionButton className="display-inline" onClick={() => onAction({ token: stakingToken, action: StakingActionType.HARVEST })}>
									<LineButton small className="w-100">
										HARVEST
									</LineButton>
								</TransactionButton>
							</div>
						</div>
				}

				<div className="px-4 pb-4 w-100">
					<div className="w-100">
						<p className="center colored clickable" onClick={() => setDetailsVisible(!detailsVisible)}>
							Details {detailsVisible ? <FontAwesomeIcon icon={faCaretUp} className="secondary-icon" /> : <FontAwesomeIcon icon={faCaretDown} className="secondary-icon" />}
						</p>
					</div>
					
					{detailsVisible &&
						<div className="w-100 mt-2">
							<p className="footnote center mb-2">Burned {denominate({ input: burned, denomination: 18, decimals: 5 })} {stakingToken.ticker}</p>
							<a href="https://krogancoin.com" className="footnote center display-block">
								Project Site <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="secondary-icon" />
							</a>
							<a href={`${network.explorerAddress}/tokens/${stakingToken.tokenId}`} className="footnote center display-block">
								Token Info <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="secondary-icon" />
							</a>
							<a href={`${network.explorerAddress}/accounts/${stakingToken.poolAddress}`} className="footnote center display-block">
								Contract <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="secondary-icon" />
							</a>
						</div>
					}
				</div>
			</div>
		</>
	)
	
}

export default StakingBoxView;