import moment from 'moment'
import BigNumber from 'bignumber.js'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { StakingToken, UserWithdrawData } from 'utils/types'
import { 
	DelegationTransactionType, denominate, ESDTToken, nominate, 
	nominateNumberToHex, nominateStringToHex, useAccount, useCoreContext, useTransactions 
} from 'core'
import { TokenStakingContract } from 'contracts/StakingContract'
import { Address } from '@multiversx/sdk-core/out'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Button, Modal } from 'react-bootstrap'
import { timeleft, timestampToString } from 'utils/time'
import { LineButton } from 'views/components/uielements/LineButton'

export enum StakingActionType {
	STAKE,
	WITHDRAW,
	HARVEST,
	UNBOUND,
}

export interface StakingModalType {
	show: boolean;
	action: StakingActionType;
	stakingToken?: StakingToken;
	handleClose: () => void;
	reload: () => void;
}
const StakingModal = ({
	show,
	action,
	stakingToken,
	handleClose,
	reload,
}: StakingModalType): React.ReactElement => {

	const tokenRef = useRef<any>(null)
	const { proxy } = useAccount()
	const { kroToken, address, lkkroTokens } = useCoreContext()

	const { sendTransaction } = useTransactions()

	const [amount, setAmount] = useState('')
	const [token, setToken] = useState(undefined as ESDTToken | undefined)
	const [userData, setUserData] = useState(null as UserWithdrawData | null)
	const [userBalance, setUserBalance] = useState([] as ESDTToken[])
	const [unbound, setUnbound] = useState(0)

	const loadData = () => {
		if (!stakingToken) return
		if (action == StakingActionType.STAKE) return

		let stakingContract = new TokenStakingContract(stakingToken.poolAddress, proxy())
		stakingContract.getUserData(address).then(data => {
			setUserData(data)
		})

		if ([StakingActionType.WITHDRAW, StakingActionType.UNBOUND].includes(action)) {
			stakingContract.getUserLockedTokens(address).then(data => {
				setUserBalance(data)
			})
		}

		if (action == StakingActionType.UNBOUND) {
			stakingContract.getUnboundPeriod(address).then(data => {
				if (data) setUnbound(data)
			})
		}
	}
	useEffect(loadData, [action, stakingToken])

	const tokens = () => {
		return kroToken ? [kroToken, ...lkkroTokens] : lkkroTokens
	}

	// TODO: change here for introducing a new token - add filter
	const availableTokens = (): string => {
		if (tokens().length > 0) {
			let total = tokens()
				.map(token => new BigNumber(token.balance))
				.reduce((prev, curr) => prev.plus(curr))
				.toString()
			return denominate({ input: total, denomination: 18, decimals: 3})
		} else {
			return '0'
		}
	}

	const updateAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.currentTarget.value.replace(',', '.')
		setAmount(value)
	}
	
	const stakeAction = () => {
		if (!token) {
			tokenRef.current?.focus();
		}
		if (isNaN(parseFloat(amount)) || !token || !stakingToken) return
		
		let nominatedAmount = nominateNumberToHex(nominate(amount, 18))
		let func = nominateStringToHex('stake')

		if (token.nonce && token.nonce > 0) {
			let tokenId = nominateStringToHex(token.ticker)
			let nonce = nominateNumberToHex('' + token.nonce ?? 0)
			let receiver = new Address(stakingToken.poolAddress).hex()
			var data = tokenId+'@'+nonce+'@'+nominatedAmount+'@'+receiver+'@'+func
			let txArguments = new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data)
			sendTransaction([txArguments], 'stake', 'Stake', () => {
				handleClose()
				reload()
			})
		} else {
			let tokenId = nominateStringToHex(token.identifier)
			var data = tokenId+'@'+nominatedAmount+'@'+func
			let txArguments = new DelegationTransactionType(stakingToken.poolAddress, '0', 'ESDTTransfer', data)
			sendTransaction([txArguments], 'stake', 'Stake', () => {
				handleClose()
				reload()
			})
		}
	}
	
	const harvestAction = () => {
		if (!stakingToken) return

		let txArguments = new DelegationTransactionType(stakingToken.poolAddress, '0', 'harvest')
		sendTransaction([txArguments], 'harvest', 'Harvest', () => {
			handleClose()
			reload()
		})
	}
	
	const reinvestAction = () => {
		if (!stakingToken) return

		let txArguments = new DelegationTransactionType(stakingToken.poolAddress, '0', 'reinvest')
		sendTransaction([txArguments], 'reinvest', 'Reinvest', () => {
			handleClose()
			reload()
		})
	}
	
	const withdrawAction = () => {
		if (!stakingToken) return

		let txArguments = new DelegationTransactionType(stakingToken.poolAddress, '0', 'withdraw', '')
		sendTransaction([txArguments], 'withdraw', 'Withdraw', () => {
			handleClose()
			reload()
		})
	}
	
	const unboundAction = () => {
		if (!stakingToken) return

		let txArguments = new DelegationTransactionType(stakingToken.poolAddress, '0', 'unbound', '', 1 + userBalance.length * 0.1)
		sendTransaction([txArguments], 'unbound', 'Unbound', () => {
			handleClose()
			reload()
		})
	}

	const setupBurnAction = () => {
		if (!stakingToken) return

		let tokenId = nominateStringToHex(stakingToken.tokenId)
		let receiver = new Address(stakingToken.poolAddress).hex()
		let role = nominateStringToHex('ESDTRoleLocalBurn')

		let data = tokenId + '@' + receiver + '@' + role
		let txArguments = new DelegationTransactionType('erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u', '0', 'setSpecialRole', data)
		sendTransaction([txArguments], 'burn', 'Burn', () => {})
	}

	const filterBy = () => true

	const stakeView = (): React.ReactElement => {
		if (!stakingToken) return <></>
		return (
			<>
			<div className="d-flex mb-2">
				<h3 className="me-auto mb-0">Stake {stakingToken.ticker}</h3>
				<p className="">Balance: {availableTokens()} {stakingToken.ticker}</p>
			</div>
			<div className="d-flex input-container center primary">
				<Typeahead
					ref={tokenRef}
					filterBy={filterBy}
					id="tokens-search"
					className="w-40"
					labelKey="name"
					minLength={0}
					options={tokens()}
					clearButton={false}
					onChange={(option) => {
						setToken(option.first() as any)
					}}
					placeholder="Select Token"
					renderMenuItemChildren={(option: any, props) => (
						<Fragment>
							<span>{denominate({input: option.balance, denomination: 18, decimals: 4})} {option.name}</span>
						</Fragment>
					)}
				>
				</Typeahead>
				<input
					className="flex-grow-1"
					type="text"
					name="amount"
					autoComplete="false"
					placeholder="Amount to Stake"
					value={amount}
					onChange={updateAmount}
				/>
				<Button variant="primary" className="text-action" onClick={stakeAction}>STAKE</Button>
			</div>
			{token && 
				<div className="mt-2">
					<p className="">Balance <strong className="colored">{denominate({input: token.balance, denomination: 18, decimals: 18})} {token.ticker}</strong></p>
					<button className="small center-button mt-2" onClick={() => 
						setAmount(denominate({input: token.balance, denomination: 18, decimals: 18, showLastNonZeroDecimal: false, addCommas: false}))
					}>MAX</button>
				</div>
			}
			</>
		)
	}

	const harvestView = (): React.ReactElement => {
		if (!stakingToken) return <></>
		if (!userData) return <p>Loading..</p>
		return (
			<>
				<h3 className="center">Harvest {stakingToken.ticker}</h3>	
				<p><strong>Earned</strong></p>
				<p className="mb-3">{denominate({input: userData.earned, denomination: 18, decimals: 5})} {stakingToken.ticker}</p>
				<LineButton className="mb-2 me-2" onClick={harvestAction} disabled={!userData.allowHarvest}>HARVEST</LineButton>
				<LineButton className="mb-2" onClick={reinvestAction}>REINVEST ALL</LineButton>
			</>
		)
	}

	const withdrawView = (): React.ReactElement => {
		if (!stakingToken) return <></>
		if (!userData) return <p>Loading..</p>
		
		let penaltyTime = new Date(userData.penaltyUntil*1000)
		return (
			<>
				<h3 className="center">Withdraw {stakingToken.ticker}</h3>
				
				<p><strong>Earned</strong></p>
				<p className="mb-3">{denominate({input: userData.earned, denomination: 18, decimals: 5})} {stakingToken.ticker}</p>

				<p><strong>Staked</strong></p>
				<div className="mb-3">
					<p>{denominate({input: userData.userTokens, denomination: 18, decimals: 5})} {stakingToken.ticker}</p>
					{userBalance.map((token, i) => (
						<p key={i}>{denominate({input: token.balance, denomination: token.decimals, decimals: 5})} {token.identifier}</p>
					))}
				</div>

				<LineButton className="mb-2" onClick={withdrawAction}>WITHDRAW ALL</LineButton>
				{penaltyTime.getTime() > new Date().getTime() &&
					<p className="footnote wrong">{userData.penalty/10}% penalty for withdrawals before&nbsp;
						{moment(penaltyTime)
							.local()
							.format('DD-MMM-YY HH:mm')}
					</p>
				}
			</>
		)
	}

	const unboundView = (): React.ReactElement => {
		if (!stakingToken) return <></>
		if (!userData) return <p>Loading..</p>
		
		return (
			<>
				<h3 className="center">Unbound {stakingToken.ticker}</h3>
				
				<p><strong>Earned</strong></p>
				<p className="mb-3">{denominate({input: userData.earned, denomination: 18, decimals: 5})} {stakingToken.ticker}</p>

				<p><strong>Staked</strong></p>
				<div className="mb-3">
					<p>{denominate({input: userData.userTokens, denomination: 18, decimals: 5})} {stakingToken.ticker}</p>
					{userBalance.map((token, i) => (
						<p key={i}>{denominate({input: token.balance, denomination: token.decimals, decimals: 5})} {token.identifier}</p>
					))}
				</div>

				<LineButton disabled={timeleft(unbound) > 0} className="mb-2" onClick={unboundAction}>UNBOUND</LineButton>
				<p>{timestampToString('Unbound available in ', 'Ready to unbound', unbound, false)}</p>
			</>
		)
	}

	const getViewByAction = () => {
		switch (action) {
			case StakingActionType.HARVEST:
				return harvestView()
			case StakingActionType.STAKE:
				return stakeView()
			case StakingActionType.WITHDRAW:
				return withdrawView()
			case StakingActionType.UNBOUND:
				return unboundView()
		}
	}


	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				className="modal-container medium"
				animation={true}
				centered
			>
				<Modal.Header closeButton className="btn-close-white"></Modal.Header>
				<Modal.Body className="center">
					{getViewByAction()}

					{stakingToken && address == stakingToken.owner && 
						<Button variant="primary" className="text-action my-2" onClick={setupBurnAction}>Setup Burning</Button>
					}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default StakingModal;
