import React from 'react'
import { quickDenominate, useCoreContext, useCoreDispatch, TokensView } from '..'

export const ConnectButtonContent = ({
	linear,
}: {
	linear?: boolean
}): React.ReactElement => {

	const coreDispatch = useCoreDispatch()
	const { isLoggedIn, address, account } = useCoreContext()

	return (
		<div className="d-flex align-items-center justify-content-center flex-stretch" onClick={() => {
			coreDispatch({ type: 'setShowLoginModal', showLoginModal: true })
		}}>
			{isLoggedIn && address != undefined && address != '' ?
				<React.Fragment>
					<img src="/images/wallet.svg" className="me-2" width="13" height="13" alt="Wallet Krogan" />
					<p className="text-action footnote">{address.truncate(12, "...")}</p>
					<i className="separator mx-2"></i>
					<div className={`d-flex ${linear ? '' : 'flex-column'}`}>
						<div className="d-flex align-items-center">
							<p className="text-action footnote">{account?.balance ? quickDenominate(account.balance, 2) : '...'}</p>
							<img src="/images/egld.svg" className="ms-1" width="13" height="13" alt="EGLD" />
						</div>
						{linear && <i className="separator mx-2"></i>}
						<TokensView includeLocked={false} small />
					</div>
				</React.Fragment>
			: 
				<React.Fragment>
					<img src="/images/connect.svg" className="me-2 img-fluid" width="10" alt="Connect wallet to Krogan" />
					<p className="text-action">CONNECT WALLET</p>
				</React.Fragment>
			}
		</div>
	)
}