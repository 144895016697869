import {
	Address, Code, CodeMetadata, SmartContract,
	Transaction, TransactionPayload, TypedValue
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { quickDenominate } from './denominate';
import { parseAmount } from '@multiversx/sdk-dapp/utils';


export interface IAccountType {
	address: string;
	balance: string;
	nonce: number;
	code?: string;
}

export interface DelegationContractType {
	name: string;
	gasLimit: number;
}

export class NetworkConfig {
	chainId: string;
	public constructor(
		chainId: string
	) {
		this.chainId = chainId;
	}
}

export class AccountType {
	balance: string;
	nonce: number;
	address: string;
	public constructor(address: string, balance = '', nonce: number) {
		this.balance = balance;
		this.nonce = nonce;
		this.address = address;
	}

	getNonceThenIncrement(): number {
		const old = this.nonce
		this.nonce++
		return old;
	}

	async reload(proxy: ProxyNetworkProvider) {
		const account = await proxy.getAccount(new Address(this.address))
		this.nonce = account.nonce
		this.balance = quickDenominate(account.balance.toString(), 3)
	}

	static empty() {
		return new AccountType('..', '0', 0)
	}
}

export class DelegationTransactionType {
	value: string;
	type: string;
	receiver: string;
	args: string;
	multiplier: number;

	public constructor(
		receiver = '',
		value = '',
		type: string,
		args = '',
		gasMultiplier = 1,
	) {
		this.value = value;
		this.type = type;
		this.args = args;
		this.receiver = receiver;
		this.multiplier = gasMultiplier;
	}

	getData(): string {
		let data = this.type;
		if (this.args !== '') {
			data = data + '@' + this.args
		}
		return data
	}

	getTransaction(chainId: string, sender: string, gas?: number): Transaction {
		const transaction = new Transaction({
			chainID: chainId,
			sender: new Address(sender),
			receiver: new Address(this.receiver),
			value: parseAmount(this.value, 18),
			data: new TransactionPayload(this.getData()),
			gasLimit: Math.round((gas ?? 12000000) * this.multiplier)
		})
		return transaction
	}
}

export class UpgradeTransactionType {
	chainId: string;
	sender: string;
	receiver: string;
	codeMetadata: CodeMetadata;
	initialArgs: TypedValue[];
	code: Code;

	/**
	 * @param sender string address
	 * @param receiver string address
	 * @param initialArgs TypedValue[]
	 * @param code use static loadCode
	 * @param codeMetadata new CodeMetadata(true, false, true, true)
	 */
	public constructor(
		sender = '',
		receiver = '',
		initialArgs = [] as TypedValue[],
		code: Code,
		codeMetadata: CodeMetadata,
	) {
		this.sender = sender
		this.initialArgs = initialArgs;
		this.chainId = '1';
		this.receiver = receiver;
		this.code = code;
		this.codeMetadata = codeMetadata;
	}

	static async loadCode(path: string): Promise<Code> {
		const file = await fetch(path)
		const buffer = Buffer.from(await file.arrayBuffer())
		return Code.fromBuffer(buffer)
	}

	getTransaction(): Transaction {
		const smartContract = new SmartContract({
			address: new Address(this.receiver)
		})
		return smartContract.upgrade({
			caller: new Address(this.sender),
			code: this.code,
			codeMetadata: this.codeMetadata,
			initArguments: this.initialArgs,
			gasLimit: 100000000,
			chainID: this.chainId
		})
	}
}

export interface ESDTToken {
	identifier: string,
	name: string,
	ticker: string,
	nonce?: number,
	owner: string,
	assets?: { pngUrl: string },
	decimals: number,
	balance: string,
	attributes?: string,
	transactions: number,
	accounts: number,
	royalties: number,
	circulatingSupply: string,
}

// USED FOR ELROND API CALLS
export interface APIESDT {
	attributes: string;
	nonce: string;
	supply: string;
	creator: string;
	name: string;
	royalties: string;
	identifier: string;
	collection: string;
	type: string;
	url: string;
	assets?: {
		pngUrl?: string;
	}
	uris: string[];
	metadata: ESDTMetaData;
	media?: ESDTMedia[];
	decimals: number;
	balance?: string;
}

// USED FOR ELROND API CALLS
export interface APICollection {
	collection: string,
	type: string,
	name: string,
	ticker: string,
	owner: string,
	assets?: {
		pngUrl: string,
		svgUrl: string,
	},
	count: number,
	localInfo?: any // used to attach DB info to object
}

export interface ESDTMedia {
	url: string;
	fileType: string;
	originalUrl: string;
	thumbnailUrl: string;
}

export interface ESDTAttribute {
	trait_type: string;
	value: string;
	rarity?: string;
}

export interface ESDTMetaData {
	id?: string;
	grade?: number;
	description: string;
	native_attributes: ESDTAttribute[];
	attributes: ESDTAttribute[];
	rarity_score?: number;
	total_minted?: number;
	rank?: number;
}