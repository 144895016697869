import BigNumber from 'bignumber.js';
import moment from 'moment';
import React, { useEffect } from 'react'
import { airdropContract, kroganNFT } from 'config';
import { 
	delay, DelegationTransactionType, Denominate, nominateNumberToHex, 
	nominateStringToHex, useCoreContext, useTransactions 
} from 'core';
import { Alert, Col, Row } from 'react-bootstrap';
import { accountService } from 'services/account';
import { Earning } from 'utils/types';
import { LineButton } from 'views/components/uielements/LineButton';

const NftStakingSection = (): React.ReactElement => {
	
	const { address, isLoggedIn } = useCoreContext()
	const { sendTransaction } = useTransactions()

	const [totals, setTotals] = React.useState({} as { [key: string]: BigNumber })
	const [airdrops, setAirdrops] = React.useState([] as Earning[])
	const [earnings, setEarnings] = React.useState([] as Earning[])

	const init = () => {
		if (!isLoggedIn) return
		accountService.fetchUser(address).then(user => {
			setEarnings(user.earnings)
		})

		accountService.fetchAirdrops(kroganNFT, 1646075482000).then((result) => {
			setAirdrops(result)
		})
	}
	useEffect(init, [address, isLoggedIn])

	const setupTotalAmounts = () => {
		const totalForUser: { [key: string]: BigNumber } = {}
		for (let i = 0; i < earnings.length; i++) {
			const earning = earnings[i];
			let airdrop = airdrops.filter(airdrop => airdrop.key == earning.key).first()
			if (!airdrop || !airdrop.token) continue

			if (totalForUser[airdrop.token.tokenId]) {
				totalForUser[airdrop.token.tokenId] = totalForUser[airdrop.token.tokenId]
					.plus(getAmountPerEarning(earning, airdrop))
			} else {
				totalForUser[airdrop.token.tokenId] = getAmountPerEarning(earning, airdrop)
			}
		}
		setTotals(totalForUser)
	}
	useEffect(setupTotalAmounts, [airdrops])

	const getAirdropData = (earning: Earning): JSX.Element | undefined => {
		if (airdrops.length == 0) return undefined
		let airdrop = airdrops.filter(airdrop => airdrop.key == earning.key).first()
		let amount = getAmountPerEarning(earning, airdrop)
		if (amount.toString() == '0') return undefined		
		return <Denominate value={amount.toString()} token={airdrop.token.tokenId} decimals={8} />
	}

	const getAmountPerEarning = (earning: Earning, airdrop?: Earning): BigNumber => {
		if (!airdrop || !airdrop.nrTokens) return new BigNumber(0)
		return new BigNumber(airdrop.nrTokens)
			.multipliedBy(earning.amount / airdrop.amount)
			.dp(0, BigNumber.ROUND_FLOOR)
	}

	const withdrawAll = () => {
		if (airdrops.length == 0) return

		accountService.fetchUserEarnings(address).then(earnings => {

			var hasValue = (key: string): boolean => {
				for (let i = 0; i < airdrops.length; i++) {
					const earning = airdrops[i];
					if (earning.key == key && earning.nrTokens && earning.token.tokenId != 'LKMEX-aab910') return true
				}
				return false
			}

			var args = []
			let allowed = airdrops.map(earning => earning.key)
			for (let i = 0; i < earnings.length; i++) {
				const earning = earnings[i];
				if (allowed.includes(earning.key) && hasValue(earning.key)) {
					args.push(nominateStringToHex(earning.key))
					args.push(nominateNumberToHex(''+earning.amount))
					args.push(earning.signature)
				}
			}
			
			let data = args.join('@')
			let txArguments = new DelegationTransactionType(airdropContract, '0', 'claimRewards', data, Math.ceil(earnings.length/5))
			sendTransaction([txArguments], 'claimRewards', 'Claim Rewards', () => delay(2000).then(init))
		})
	}

	return (
		<Row>
			<Col xs={2} sm={3} className="my-4 center">
				<img src="/images/staking/krorewards.png" className="img-fluid rewards-image" alt='Earn rewards from Krogan' />
			</Col>
			{address && earnings && earnings.length > 0 && airdrops.length > 0 ? (
				<Col xs={8} sm={6} className="mt-5 mb-4 center">
					<h3>Pending earnings for this address</h3>

					{earnings.map((earning: Earning, i: number) => (
						<div key={i} className="my-2">
							<h5>Airdrop {moment(earning.createdAt).fromNow()}</h5>
							{getAirdropData(earning) ? getAirdropData(earning) : <p>Deposit for this airdrop will be on Sunday.</p>}
						</div>
					))}
					
					{earnings.length > 0 &&
					<>
						
						<LineButton className="mt-4" onClick={withdrawAll}>WITHDRAW ALL</LineButton>
						
						{Object.keys(totals).map(key => (
							<div className="mt-1" key={key}>
								<span>Total: </span>
								<Denominate value={totals[key].toString()} token={key} decimals={8} />
								{key == 'LKMEX-aab910' &&
									<p className="footnote">LKMEX airdrops will be skipped for now. Transfer role is active only for XMEX.</p>
								}
							</div>
						))}

						<Alert variant="dark" className='mt-3'>
							<strong>General Info</strong><br/>
							To avoid transaction fees you can let the funds gather for multiple weeks and withdraw them all at once.
							<br/><br/>
							<strong>Any unclaimed airdrop older than 12 weeks will be lost.</strong>
						</Alert>
					</>
					}
				</Col>
			): 
				address ?
					<Col xs={8} sm={6} className="mt-5 mb-4 center">
						<div className="vertical-center">
							<h3>No pending earnings for this address.</h3>
							<h3>To be eligible you need to hold at least 3 NFTs.</h3>
							<p>Every Sunday, an airdrop will be distributed to Krogan NFT owners.</p>
						</div>
					</Col>
					:
					<Col xs={8} sm={6} className="mt-5 mb-4 center">
						<p className="center vertical-center">Please connect to see your earnings for this collection.</p>
					</Col>
				
			}
			<Col xs={2} sm={3} className="my-4 center">
				<img src="/images/staking/10level.gif" className="img-fluid rewards-image" alt="Earn rewards from Krogan" />
			</Col>
		</Row>
	);
}

export default NftStakingSection