import { useGetAccountInfo, useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { delegationContractData, network } from 'config';
import { DelegationTransactionType, UpgradeTransactionType } from 'core/helpers';
import { signMessage } from '@multiversx/sdk-dapp/utils';
import { useState } from 'react';
import { useAccount } from './useAccount';

export function useTransactions() {
	
	const { address } = useGetAccountInfo()

	const [sessionId, setSessionId] = useState(null as string | null)
	const { addTx, removeTx, inProgressTx, refreshTokenList } = useAccount()
	
	// todo: return errorMessage
	const _ = useTrackTransactionStatus({
		transactionId: sessionId,
		onSuccess: (transactionId) => {
			setSessionId(null)
			if (!transactionId) return
			refreshTokenList()
			const func = inProgressTx[transactionId]
			if (func) {
				func(true)
				removeTx(transactionId)
			} else {
				console.log(`No callback found for tx`)
			}
		},
		onFail: (transactionId) => {
			setSessionId(null)
			if (!transactionId) return
			const func = inProgressTx[transactionId]
			if (func) {
				func(false)
				removeTx(transactionId)
			} else {
				console.log(`No callback found for tx`)
			}
		},
		onCancelled: () => {
			setSessionId(null)
		},
	});
	
	const addTransaction = (sessionId: string, func: (success: boolean) => void) => {
		addTx(sessionId, func)
		setSessionId(sessionId)
	}

	const sendTransaction = async (args: DelegationTransactionType[], id: string, comment: string, onSuccess: (success: boolean) => void, enqueTx?: boolean): Promise<string> => {
		return new Promise<string>((resolve, reject) => {
			sendTransactions({
				transactions: args.map(transaction => {
					const delegationContract = delegationContractData.find(d => d.name === transaction.type);
					return transaction.getTransaction(network.chainId, address, delegationContract?.gasLimit)
				}),
				sessionInformation: id,
				transactionsDisplayInfo: {
					successMessage: comment,
					processingMessage: comment,
				},
				sendSeparated: enqueTx,
			}).then(({ sessionId, error }) => {
				if (error) {
					reject(error)
				} else {
					addTransaction(sessionId, onSuccess)
					resolve(sessionId)
				}
			});
		})		
	}

	const sendUpgradeTransaction = async (args: UpgradeTransactionType, id: string, comment: string): Promise<string> => {
		const transaction = args.getTransaction()
		transaction.setChainID(network.chainId)
		
		return new Promise<string>((resolve, reject) => {
			sendTransactions({
				transactions: transaction,
				transactionsDisplayInfo: comment,
				sessionInformation: id,
			}).then(({ sessionId, error }) => {
				if (error) {
					reject(error)
				} else {
					resolve(sessionId)
				}
			});
		})		
	}

	const signAccountMessage = async (message: string): Promise<string> => {
		const signed = await signMessage({ message: message })
		return signed.getSignature().toString('hex') ?? ''
	}

	return {
		sessionId,
		sendTransaction,
		signAccountMessage,
		sendUpgradeTransaction,
	}
}