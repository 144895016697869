import { Address, CodeMetadata, StringValue, U32Value, U64Value } from '@multiversx/sdk-core/out';
import { kroganNFT, kroTicker, nftAttributesContract, nftMinterContract } from 'config';
import { 
	DelegationTransactionType, nominateNumberToHex, 
	nominateStringToHex, nominateValToHex, 
	UpgradeTransactionType, useCoreContext, useTransactions 
} from 'core';
import React from 'react';

const AdminView = (): React.ReactElement => {
	
	const { address } = useCoreContext()
	const { sendTransaction, sendUpgradeTransaction } = useTransactions()
	

	const withdraw = () => {
		let txArguments = new DelegationTransactionType(nftMinterContract, '0', 'withdraw')
		sendTransaction([txArguments], 'withdraw', 'Claim Rewards', () => {})
	}

	const claimRewards = () => {
		let txArguments = new DelegationTransactionType(nftMinterContract, '0', 'ClaimDeveloperRewards')
		sendTransaction([txArguments], 'withdraw', 'Claim Rewards', () => {})
	}

	const burnKro = () => {
		// 18 = 000000000000000000
		const kro = '4b524f2d646639376563' // KRO-df97ec
		const amount = nominateNumberToHex('150000000000000000000000') // 250m
		const data = [kro, amount].join('@')
		let txArguments = new DelegationTransactionType(address, '0', 'ESDTLocalBurn', data)
		sendTransaction([txArguments], 'burn', 'Burn KRO', () => {})
	}

	const burnKroRole = () => {
		// 18 = 000000000000000000
		const kro = '4b524f2d646639376563' // KRO-df97ec
		const role = '45534454526f6c654c6f63616c4275726e' // ESDTRoleLocalBurn
		const hexAddress = new Address('erd1qqqqqqqqqqqqqpgqxetccmv9rjefu2fwtw7a6kkx0tsqtqxpp4ssj8shz4').hex()
		const data = [kro, hexAddress, role].join('@')
		let txArguments = new DelegationTransactionType('erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u', '0', 'setSpecialRole', data)
		sendTransaction([txArguments], 'burn role', 'Burn KRO ROLE', () => {})
	}

	const createCategories = () => {
		const data = [
			'000004160000046e0000033d000002970000025e0000014000000000000000000000000000000000', 
			nominateValToHex('20000'), 
			nominateStringToHex('https://gateway.ipfs.io/ipfs/QmeeQK6V9uv71xyNbGy5q1PHRuBHy1EYXVMs9sS5bP1iYY/')
		].join('@')
		let txArguments = new DelegationTransactionType(nftMinterContract, '0', 'createCategories', data)
		sendTransaction([txArguments], 'categories', 'Create Categories', () => {})
	}

	const updateToken = () => {
		const data = nominateStringToHex(kroTicker)
		let txArguments = new DelegationTransactionType(nftMinterContract, '0', 'setPaymentToken', data)
		sendTransaction([txArguments], 'token udpate', 'Token Update', () => {})
	}

	const upgrade = async () => {
		const code = await UpgradeTransactionType.loadCode('/contract/nft-minter.wasm')
		console.log(code)
		const txArgs = new UpgradeTransactionType(
			address,
			nftMinterContract,
			[new U64Value(1641747600), new U64Value(1641758400), new U32Value(10), new StringValue(kroTicker)],
			code, new CodeMetadata(true, false, true, true)
		)
		sendUpgradeTransaction(txArgs, 'upgrade', 'Upgrade Contract')
	}

	const attributesRoles = () => {
		const krogan = nominateStringToHex(kroganNFT)
		const role1 = nominateStringToHex('ESDTRoleNFTBurn')
		const role2 = nominateStringToHex('ESDTRoleNFTUpdateAttributes')
		const hexAddress = new Address(nftAttributesContract).hex()
		const data = [krogan, hexAddress, role1, role2].join('@')
		let txArguments = new DelegationTransactionType('erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u', '0', 'setSpecialRole', data)
		sendTransaction([txArguments], 'setup attributes', 'Setup Attributes', () => {})
	}

	return (
		<div className="my-4">
			<button className="center-button text-action" onClick={withdraw}>Withdraw</button>
			<button className="center-button text-action" onClick={claimRewards}>Claim Rewards</button>
			<button className="center-button text-action" onClick={burnKroRole}>Burn role</button>
			<button className="center-button text-action" onClick={burnKro}>Burn KRO</button>
			<button className="center-button text-action" onClick={upgrade}>Upgrade</button>
			<button className="center-button text-action" onClick={createCategories}>Categories</button>
			<button className="center-button text-action" onClick={updateToken}>Token Update</button>
			<button className="center-button text-action" onClick={attributesRoles}>Attributes Roles</button>
		</div>
	);
};

export default AdminView;
