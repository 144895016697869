import BigNumber from 'bignumber.js'
import { TokenImage } from 'core';
import React, { useEffect, useState } from 'react';
import { tokenPrice } from 'services/blockchain';
import { LineButton } from '../uielements/LineButton';

const TokenPrice = (): React.ReactElement => {
		
	const [price, setPrice] = useState('0');

	const tokenId = 'KRO-df97ec'
	const stable = 'USDC-c76f1f'

	useEffect(() => {
		tokenPrice(tokenId).then(price => {
			setPrice(new BigNumber(price).toFixed(5))
		})
	}, [])

	return (
		<div className="my-4">
			<p>
				<LineButton url="https://kroganverse.com" blankPage className='me-3'>Play Game</LineButton>
				<LineButton url="/swap">Buy KRO</LineButton>
				<span className="ms-3">1 { TokenImage(tokenId) } = {price} { TokenImage(stable) }</span>
			</p>
		</div>
	);
};

export default TokenPrice;
