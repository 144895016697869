import React, { useEffect } from 'react'
import { NftModel } from 'models/NftModel';
import { LineButton } from '../uielements/LineButton';
import { delay, DelegationTransactionType, Denominate, nominateNumberToHex, nominateStringToHex, useAccount, useCoreContext, useTransactions } from 'core';
import { nftAttributesContract, resetPrice } from 'config';
import { Address, BinaryCodec, Field, FieldDefinition, StringType, StringValue, Struct, StructType, U32Type, U32Value } from '@multiversx/sdk-core/out';
import { contractViews } from 'contracts/ContractViews';
import { attributeConfig } from 'utils/attributes.config';
import { Col, Row } from 'react-bootstrap';
import { AttributeKey } from 'utils/attributes.config';
import { kroTicker } from 'config';
import nftData from '../../../utils/nft.fix.json';

interface NFTAttributesRarity {
	[key: string]: string;
	rarity: string;
}

export const NFTView = ({
	index,
	esdt,
	collection,
	reload,
	extraPoints,
	upgradeable,
}: {
	index: number;
	esdt: NftModel;
	collection: { [key: string]: any };
	reload: () => void;
	extraPoints?: number;
	upgradeable?: boolean;
}): React.ReactElement => {

	const { proxy } = useAccount()
	const { address } = useCoreContext()
	const { sessionId, sendTransaction } = useTransactions()

	const [points, setPoints] = React.useState(0)
	const [attributes, setAttributes] = React.useState({} as NFTAttributesRarity)
	const [usedPoints, setUsedPoints] = React.useState({} as { [key: string]: number })
	const [upgrades, setUpgrades] = React.useState(undefined as { [key: string]: number } | undefined)
	const [totalUsed, setTotalUsed] = React.useState(0)
	const [needsFix, setNeedsFix] = React.useState(false)

	useEffect(() => {
		generateAttributes()
		loadPoints()
	}, [esdt]);

	const loadPoints = async () => {
		if (!esdt.isRegistered()) return setPoints(0)
		await delay(index * 200)
		const points = await contractViews.getPoints(proxy(), parseInt(esdt.nonce))
			.catch(err => {
				console.log(err)
			})
		if (points) setPoints(points)
	}

	const loadUpgrades = async (force = false) => {
		if (!esdt.isRegistered()) return
		if (!force && upgrades) return

		const nftFixed = await contractViews.nftFixed(proxy(), parseInt(esdt.nonce))
			.catch(err => {
				console.log(err)
			})
		setNeedsFix(!nftFixed)

		if (nftFixed) {
			const list = await contractViews.allUpgrades(proxy(), parseInt(esdt.nonce))
			.catch(err => {
				console.log(err)
			})
			if (!list) return

			let object: { [key: string]: number } = {}
			object['Speed'] = list[0]
			object['Acceleration'] = list[1]
			object['Evasion'] = list[2]
			object['Attack'] = list[3]
			object['Accuracy'] = list[4]
			object['Fire Rate'] = list[5]
			object['Armor'] = list[6]
			object['Health'] = list[7]
			object['Cargo'] = list[8]
			object['Mining'] = list[9]

			setUpgrades(object)
		} else {
			const data = (nftData as any)[esdt.nonce]
			if (!data) {
				setUpgrades({})
				return
			}
			let object: { [key: string]: number } = {}
			if (data['speed']) object['Speed'] = data['speed']
			if (data['acceleration']) object['Acceleration'] = data['acceleration']
			if (data['evasion']) object['Evasion'] = data['evasion']
			if (data['attack']) object['Attack'] = data['attack']
			if (data['accuracy']) object['Accuracy'] = data['accuracy']
			if (data['fireRate']) object['Fire Rate'] = data['fireRate']
			if (data['armor']) object['Armor'] = data['armor']
			if (data['health']) object['Health'] = data['health']
			if (data['cargo']) object['Cargo'] = data['cargo']
			if (data['mining']) object['Mining'] = data['mining']

			setUpgrades(object)
		}
	}

	const generateAttributes = () => {
		var attributes: NFTAttributesRarity = {
			rarity: ''
		}
		var rarityScore = 0
		esdt.metadata.attributes.forEach((attribute) => {
			attributes[attribute.trait_type] = (collection[attribute.trait_type][attribute.value]['attributeFrequency'] * 100).toFixed(2)
			rarityScore += collection[attribute.trait_type][attribute.value]['attributeRarity']
		})
		attributes.rarity = (rarityScore * 7).toFixed(0)
		setAttributes(attributes)
	}

	const register = () => {
		const args = []
		args.push(nominateStringToHex(esdt.collection))
		args.push(nominateNumberToHex(esdt.nonce.toString()))
		args.push(nominateNumberToHex('1'))
		args.push(new Address(nftAttributesContract).hex())
		args.push(nominateStringToHex('registerNft'))

		const data = args.join('@')
		const txArguments = new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data)
		sendTransaction([txArguments], 'registerNft', 'Register NFT', () => delay(2000).then(reload))
	}

	const getStructure = (): StructType => {
		return new StructType('UpgradeParam', [
			new FieldDefinition('key', '', new StringType()),
			new FieldDefinition('points', '', new U32Type()),
		])
	}

	const upgrade = () => {
		if (!upgrades) return
		
		const codec = new BinaryCodec()

		const txs = []

		if (needsFix) {
			const args = []
			args.push(nominateNumberToHex(esdt.nonce.toString()))

			const vec: string[] = []
			Object.keys(upgrades).forEach((key) => {
				const fixedKey = key.toLowerCase().replace('fire rate', 'fireRate')
				vec.push(codec.encodeNested(new Struct(getStructure(), [
					new Field(new StringValue(fixedKey), 'key'),
					new Field(new U32Value(upgrades[key]), 'points'),
				])).toString('hex'))
			})
			args.push(vec.join(''))
			const data = args.join('@')
			txs.push(new DelegationTransactionType(nftAttributesContract, '0', 'updateNft', data))
		}

		const args = []
		args.push(nominateStringToHex(esdt.collection))
		args.push(nominateNumberToHex(esdt.nonce.toString()))
		args.push(nominateNumberToHex('1'))
		args.push(new Address(nftAttributesContract).hex())
		args.push(nominateStringToHex('upgradeNft'))

		
		const vec: string[] = []
		Object.keys(usedPoints).forEach((key) => {
			const fixedKey = key.toLowerCase().replace('fire rate', 'fireRate')
			vec.push(codec.encodeNested(new Struct(getStructure(), [
				new Field(new StringValue(fixedKey), 'key'),
				new Field(new U32Value(usedPoints[key]), 'points'),
			])).toString('hex'))
		})
		args.push(vec.join(''))

		const data = args.join('@')
		txs.push(new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data))
		sendTransaction(txs, 'upgradeNft', 'Upgrade NFT', () => {
			delay(2000).then(reload)
			setUsedPoints({})
			loadUpgrades(true)
		}, true)
	}

	const resetPoints = () => {
		if (!upgrades) return

		const txs = []

		if (needsFix) {
			const args = []
			args.push(nominateNumberToHex(esdt.nonce.toString()))

			const vec: string[] = []
			const codec = new BinaryCodec()
			Object.keys(upgrades).forEach((key) => {
				const fixedKey = key.toLowerCase().replace('fire rate', 'fireRate')
				vec.push(codec.encodeNested(new Struct(getStructure(), [
					new Field(new StringValue(fixedKey), 'key'),
					new Field(new U32Value(upgrades[key]), 'points'),
				])).toString('hex'))
			})
			args.push(vec.join(''))
			const data = args.join('@')
			txs.push(new DelegationTransactionType(nftAttributesContract, '0', 'updateNft', data))
		}

		const args = []

		args.push(new Address(nftAttributesContract).hex())
		args.push(nominateNumberToHex('2'))

		args.push(nominateStringToHex(esdt.collection))
		args.push(nominateNumberToHex(esdt.nonce.toString()))
		args.push(nominateNumberToHex('1'))

		args.push(nominateStringToHex(kroTicker))
		args.push(nominateNumberToHex('0'))
		args.push(nominateNumberToHex(resetPrice))

		args.push(nominateStringToHex('resetNft'))

		const data = args.join('@')
		txs.push(new DelegationTransactionType(address, '0', 'MultiESDTNFTTransfer', data))
		sendTransaction(txs, 'resetNft', 'Reset NFT Points', () => {
			delay(2000).then(reload)
			setUsedPoints({})
			loadUpgrades()
		})
	}

	const fixNft = () => {
		const args = []
		args.push(nominateStringToHex(esdt.collection))
		args.push(nominateNumberToHex(esdt.nonce.toString()))
		args.push(nominateNumberToHex('1'))
		args.push(new Address(nftAttributesContract).hex())
		args.push(nominateStringToHex('fixNft'))

		const data = args.join('@')
		const txArguments = new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data)
		sendTransaction([txArguments], 'fixNft', 'Fix NFT', () => {
			delay(2000).then(reload)
			setUsedPoints({})
			loadUpgrades(true)
		})
	}

	const totalUpgrades = () => {
		let total = 0
		if (!upgrades) return total
		Object.keys(upgrades).forEach(key => { total += upgrades[key] })
		return total
	}

	const totalUsedPoints = () => {
		let total = 0
		Object.keys(usedPoints).forEach(key => { total += usedPoints[key] })
		setTotalUsed(total)
		
		if (total > 0)
			loadUpgrades()
	}
	useEffect(totalUsedPoints, [usedPoints])

	const checkIfResetIsNeeded = () => {
		if (!upgrades) return
		const keys = [
			AttributeKey.Speed, AttributeKey.Acceleration, AttributeKey.Accuracy, 
			AttributeKey.Armor, AttributeKey.Attack, AttributeKey.Cargo, AttributeKey.Evasion,
			AttributeKey.FireRate, AttributeKey.Health, AttributeKey.Mining
		]
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i]
			const config = attributeConfig(key)
			const current = totalAdded(key) + ((usedPoints[key] ?? 0) + upgrades[key]) * config.step
			
			if (current > config.max) {
				setUsedPoints({
					...usedPoints,
					[key]: 0
				})
			}
		}
	}
	useEffect(checkIfResetIsNeeded, [upgrades])

	const addPoint = (key: string) => {
		if (points + (extraPoints ?? 0) == totalUsed) return
		const rule = attributeConfig(key)
		const current = ((usedPoints[key] ?? 0) + previousUpgrade(key)) * rule.step
		if (current >= rule.max) return
		
		const spaceshipMax = rule.start + rule.max + rule.levelInc * parseInt(esdt.level) + rule.randomSize
		const attr = esdt.getAttributeValue(key)
		if ((usedPoints[key] ?? 0) * rule.step + parseInt(attr.value) >= spaceshipMax) return

		setUsedPoints({
			...usedPoints,
			[key]: (usedPoints[key] ?? 0) + 1
		})
	}

	const removePoint = (key: string) => {
		setUsedPoints({
			...usedPoints,
			[key]: Math.max((usedPoints[key] ?? 0) - 1, 0)
		})
	}

	const totalAdded = (key: string): number => {
		const config = attributeConfig(key)
		if (!config) return 0
		return (usedPoints[key] ?? 0) * config.step
	}

	const shouldBeFixed = (): boolean => {
		if (!upgrades) return false
		return Object.keys(upgrades)
			.map(key => { 
				const rule = attributeConfig(key)
				const attr = esdt.getAttributeValue(key)
				const maxForUsedUpgrades = upgrades[key] * rule.step + rule.start 
					+ rule.randomSize + rule.levelInc * parseInt(esdt.level)

				return parseInt(attr.value) > maxForUsedUpgrades || 
					upgrades[key] * rule.step > rule.max
			})
			.reduce((acc, next) => acc || next, false)
			&& [
				'erd1ks8gapjup6k29algnmk44t6pvp8ayys99npm8qsex4y7q49kquzqwa22kr',
				'erd1lvks4kr8rnk5h9mdkyrr5f6e0p0s22qtt5s0rqg3q25sfuzkrewsvuwqhc'
			].includes(address)
	}

	const canEdit = (): boolean => {
		return upgrades != undefined
	}

	const previousUpgrade = (trait: string): number => {
		if (!upgrades) return 0
		return upgrades[trait]
	}

	return (
		<div className='card mb-4 p-3'>
			<Row>
				<Col xs={6} className="">
					<img src={esdt.url} className="center round-corners float-image" alt="Krogan Spaceship" />
				</Col>
				<Col xs={6} className="text-left">
					<h4 className="mb-0">{esdt.name}</h4>
					<h5 className="mt-1 mb-0">Level {esdt.level}</h5>
					<p className="mt-2 footnote">Visual Rarity Score {attributes.rarity}</p>

					<h5 className="mt-2 mb-1">Visual Attributes</h5>
					{esdt.metadata.attributes.map((attribute, i) => (
						<div key={i}>
							<p className="caption">
								{attribute.trait_type}: <span className="caption colored">{attribute.value}</span>
								&nbsp;(&nbsp;{attributes[attribute.trait_type]}%&nbsp;)
							</p>
						</div>
					))}
				</Col>
				<Col md={6}>
					{upgradeable ?
						<>
							{esdt.isRegistered() ?
								<React.Fragment>
									<p className='mt-3'><span className="colored">{points}</span> upgrade points</p>
									{canEdit() == false ? <>
										<LineButton small className="mt-2" onClick={loadUpgrades}>LOAD USED POINTS</LineButton>	
										{!canEdit() && <p className='caption mt-2'>Load the points and start upgrading</p>}
									</> :
										totalUpgrades() > 0 && 
										<>
											<LineButton small className="mt-2" onClick={resetPoints}>RESET USED POINTS</LineButton>
											<p className='mt-1 footnote'>Reset cost: <Denominate value={resetPrice} token={kroTicker} decimals={0} /></p>
										</>
									}
									{totalUsed > 0 &&
									<>
										<LineButton className="mt-3" onClick={upgrade}>UPGRADE</LineButton>
										<p className='footnote mt-1'>Used <span className='colored footnote'>{totalUsed}</span> points</p>
									</>
									}

									{shouldBeFixed() &&
									<>
										<LineButton small className="mt-3" onClick={fixNft}>FIX ATTRIBUTES</LineButton>
										<p className='footnote mt-1'>Something is wrong with this NFT</p>
									</>
									}
								</React.Fragment>
								:
								<LineButton className="my-2" small sessionId={sessionId} onClick={register}>REGISTER</LineButton>
							}
						</>
						:
						<p><span className="colored">{points}</span> upgrade points</p>
					}
				</Col>
				<Col md={6} className='text-left-xs'>
					{esdt.isRegistered() &&
						<React.Fragment>
							<h5 className="mt-2 mb-1">Dynamic Attributes</h5>
							{esdt.dynamicAttributes.map((attribute, i) => (
								<div key={i} style={{ maxWidth: 200, margin: '0 auto' }}>
									<p className="caption mb-1 text-left">
										<span className="caption" style={{ display: 'inline-block', width: 75 }}>
											{attribute.trait_type}:
										</span>
										{upgradeable && canEdit() &&
											<LineButton type="primary" className="xs" onClick={() => removePoint(attribute.trait_type)}>-</LineButton>
										}
										<span className={`caption center ${usedPoints[attribute.trait_type] ? 'correct' : ''}`} style={{ display: 'inline-block', width: 30 }}>
											{parseInt(attribute.value) + totalAdded(attribute.trait_type)}
										</span>
										{upgradeable && canEdit() &&
											<LineButton type="primary" className="xs" onClick={() => addPoint(attribute.trait_type)}>+</LineButton>
										}
										{upgradeable && (previousUpgrade(attribute.trait_type) > 0 || usedPoints[attribute.trait_type] > 0) &&
											<span className='caption correct'>+{(previousUpgrade(attribute.trait_type) ?? 0) + (usedPoints[attribute.trait_type] ?? 0)}</span>
										}
									</p>
								</div>
							))}							
						</React.Fragment>
					}
				</Col>
			</Row>
		</div>
	);
};
