import React from 'react'

import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import FooterSection from '../sections/Main/FooterSection'
import RaritySection from '../sections/Nft/RaritySection'

const Rarity = () => {

	return (
		<>
			<Container fluid>
				
				<RaritySection alt={false} />
				<FooterSection alt={true} />

			</Container>
		</>
	)
}

export default withRouter(Rarity);