import 'regenerator-runtime/runtime'
import React, { Fragment } from 'react'
import BigNumber from 'bignumber.js';
import { Switch, Route } from 'react-router-dom'
import { ConnectModal, useCoreContext, useCoreDispatch } from 'core';

import './App.scss'
import Main from './views/routes/Main'
import Rarity from 'views/routes/Rarity';
import Partners from 'views/routes/Partners';
import Discord from 'views/routes/Discord';
import Swap from 'views/routes/Swap';
import Staking from 'views/routes/Staking';
import NftStaking from 'views/routes/NftStaking';


const App = (): React.ReactElement => {

	const coreDispatch = useCoreDispatch()
	
	const { showLoginModal } = useCoreContext()

	React.useEffect(() => {
		BigNumber.config({ 
			EXPONENTIAL_AT: 30,
			DECIMAL_PLACES: 4,
			ROUNDING_MODE: BigNumber.ROUND_HALF_UP
		})	
	}, [])

	return (
		<Fragment>
			<ConnectModal show={showLoginModal} onHide={() => {
				coreDispatch({ type: 'setShowLoginModal', showLoginModal: false })
			}} /> 
			<Switch>
				<Route path="/nft-staking">
					<NftStaking />
				</Route>
				<Route path="/staking">
					<Staking />
				</Route>
				<Route path="/swap">
					<Swap />
				</Route>
				<Route path="/connect/:discordId">
					<Discord />
				</Route>
				<Route path="/partners">
					<Partners />
				</Route>
				<Route path="/fleet">
					<Rarity />
				</Route>
				<Route path="/">
					<Main />
				</Route>
			</Switch>
		</Fragment>
	);
}

export default App;