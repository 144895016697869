import React, { useEffect } from 'react'
import { useCoreContext, useCoreDispatch } from 'core';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { accountService } from 'services/account';
import { LineButton } from 'views/components/uielements/LineButton';

type Params = {
	discordId: string;
};

const DiscordSection = (): React.ReactElement => {

	let { discordId } = useParams<Params>();
	let { address, isLoggedIn } = useCoreContext();
	const coreDispatch = useCoreDispatch();

	const connectWalet = () => {
		coreDispatch({ type: 'setShowLoginModal', showLoginModal: true })
	}

	const sendRequest = () => {
		if (!isLoggedIn || !discordId) return
		accountService.linkDiscord(discordId, address, (discordId+address).hashCode())
	}
	useEffect(sendRequest, [isLoggedIn, discordId, address]);

	return (
		<Row id="token-benefits" className="section">
			<Col xs={12} className="center">
				{isLoggedIn ?
					<p>Account connected! You can return to Discord now!</p>
				:
					<LineButton className="my-2" onClick={connectWalet}>
						CONNECT WALLET
					</LineButton>
				}
			</Col>
		</Row>
	);
}

export default DiscordSection;