import React from 'react'
import classNames from "classnames";
import { Col, Row } from 'react-bootstrap';
import ReadMore from 'views/components/Others/ReadMore';

import driftersBanner from 'images/partners/drifters_banner.png'
import drifters from 'images/partners/drifters.jpeg'
import qowatt from 'images/partners/qowatt.png'
import warriorz from 'images/partners/warriorz.jpg'
import plata from 'images/partners/plata.jpeg'
import cybers from 'images/partners/cybers.jpeg'
import zoidpay from 'images/partners/zoidpay.jpeg'
import aerovek from 'images/partners/aerovek.jpeg'
import srb from 'images/partners/srb.png'
import pittz from 'images/partners/pittz.jpeg'
import ducks from 'images/partners/ducks.jpeg'

const PartnerSection = ({
    alt,
}: {
    alt: boolean;
}): React.ReactElement => {



	return (
		<Row id="content" className={classNames({
			section: true,
			alt: alt
		})}>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={drifters} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - Drifters" 
						/>
						<p className="tag caption">Rogue</p>
						<p className="tag caption">Flight Pilot</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Acceleration: +15</p>
						<p>Speed: +25</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://www.driftersnft.com/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/drifters_nft" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>1. Drifters - &quot;To unite and empower disrupters in building a better reality&quot;</h3>

						<ReadMore content={
							<p>
								A long time ago far across the stars, the Neyta civilization became so powerful they mastered earth, water, and sky. Over thousands of years, they grew under the blessing of the eternal star, their purple sun, « Ethereum ». <br />
								Neytas took pride in knowing they could harvest, build and expand with no limit. Shaping their homeworld into a technological marvel, they pushed civilizational boundaries so far that one planet wasn&apos;t enough anymore.<br />
								They needed more… So they turned to the stars, and leaning on Ethereum&apos;s endless energy, they conquered space.<br /><br />
								But one day, the one thing that defined the Neyta civilization collapsed. Ethereum, the purple sun, started to show signs of weakness. The eternal star couldn&apos;t support any more expansion, and its resources weren&apos;t enough anymore. Ethereum&apos;s energy became scarcer, and not so long after the Neytas started to turn on each other… 
								<br /><br />
								From that day Neyta people discovered their true nature. <br />
							</p>
						} readMore={
							<>
							<p>
								As some say about this dark age: <br />
								<i>For when plenty becomes few<br />
								The soul feeds on the flesh<br />
								Under the dying star<br />
								You will discover oneself</i>
								<br />
							</p>
							<img src={driftersBanner} className="img-fluid banner round-corners" alt="Krogan Drifters species" />
							<p>
								For hundreds of years, the vicious circle of scarcity started devouring the Neyta civilization at the core. Anything that could be turned into energy became fuel, anyone that was too weak became a slave.<br />
								Darkness was everywhere, but when all hope seemed lost a small light was lit.<br />
								<br />
								A utopian tech entrepreneur decided to sell his life work to embark on the greatest adventure of his life. For years he started pitching an alternative way of life. One where people would look out for each other, one where community development was tied with sustainable growth, one where each individual is free to be oneself… 
								He struggled to find those free spirits, often broken by their gloomy day-to-day, often too scared to step outside of the shadows to be who they really are.<br />
								But in the end, one by one, he managed to create a formidable group of 1000 innovators. Often seen as misfits or dreamers by society, to him they were the key to salvation. <br />
								Under his leadership, a spark was ignited among them and they decided it was time to act. They were going to look for a new place to create a different future. A future that needed a reset of who they were so they could build a society based on new ideals.<br />
								<br />
								This is the story of a group of free spirits trying to change the world.<br />
								This is the story of the Drifters.<br />
								Maybe… This is your story.<br />
							</p>
							</>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={qowatt} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - QoWatt" 
						/>
						<p className="tag caption">Energy</p>
						<p className="tag caption">Brand</p>
						<p className="tag caption">Items</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://qowatt.network/en/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/QoWatt_Token" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>2. QoWatt - The power source of the galaxy</h3>

						<ReadMore content={
							<p>
								Well, where do we start? It was the year 280.392. Everybody&apos;s left the Krogan Planet, in the search of a new beginning. There were new planets to colonise. New materials to harvest. New intergalactic energies in the universe to understand. 
								<br /><br />
								Much has changed since back then. But there was still one thing, something they were about to discover. They just didn&#39;t quite know the magnitude of what it was.
								<br /><br />
								The story says that one day, while a spaceship was travelling, they suddenly saw something strange coming from a distant nebula. Something they had never seen previously on their journeys. It appeared to be a cloud of green energy pulsing in waves. They were curious and following their instinct, they went to see if they could somehow capture a part of that energy, to study and harness its power…
								<br /><br />
								Little did they know, their spaceship would be completely destroyed. 
							</p>
						} readMore={
							<p>
								The radiation from the nebula was so strong, it took hold and instantly things got out of control. Nobody quite knew what happened that day or the significance this new powerful energy source would hold. 
								<br /><br />
								After others found out about the destruction and the green energy phenomenon, they said they had to try again. The sacrifice of the crew could not be in vain and such power had to be utilised somehow. So they tried again. And again, the energy was so strong, that even taking a small sample led to the same fate as those who tried before them. It looked like there was no hope anymore. Most scientists even stopped trying.
								<br /><br />
								Until one day… After much deliberation, one of the scientists decided that it was time to take a risk. 
								<br /><br />
								He was the one that would either destroy everything, or he would form a new dawn of time for his people, the one to tame an energy source so powerful, others before him had perished in their endeavour.
								<br /><br />
								But who was he?...
								<br /><br />
								Not many things are known about his past. Some say he is one of the successors of the Krogan Prime, the ancient family that mastered unbelievable technologies and built the Old Krogan Empire, but he wished to keep his identity secret. Nobody knows the truth. But who knows, maybe one day…
								<br /><br />
								One thing&#39;s for sure. He always had a deft instinct to search and survive the unknown. To find and create what others feared to discover.
								<br /><br />
								So he decided to follow his instincts and take the path of science, dedicating his studies to the energetic field. Many years passed and one day, together with his crew, they found out about this pulsing green energy that could cause such devastation, that no one wanted to continue with their quest.
								<br /><br />
								There must be a way to make use of it. But studies found that if combined with the wrong materials, this could even cause mass destruction. Many had warned him to stop. But he was driven and determined to try again. He was just too close to give up now. 
								<br /><br />
								For years they tried to develop a prototype that could properly capture the energy, combine it with other materials and bring it to a stable form, while still maintaining its great power. So when they thought it&apos;s finally ready, they decided to give it a try with a bigger amount of energy now. They got close to the energy field rippling out from the nebula and tried to harness its power with their new prototype. This could be a life or death situation. This could destroy them if the energy would get unstable again…
								<br /><br />
								They could not believe it. They had finally achieved what others before them had perished trying to do. The prototype had succeeded in containing the energy into a stable form. This would revolutionise everything!
								<br /><br />
								A few more years passed, the energy generators that were developed following this intrepid mission, were capable of powering large hubs and infrastructure in the solar system. 
								His famous generator started to gain a lot of traction. It wasn&apos;t long before all the Factions started asking for the generators to be used in strategic places throughout the galaxy. 
								<br /><br />
								This is how QoWatt power generators were born. They would orbit around planets, traversing systems, signalling a bright beacon for life and prosperity. A truly universal power source for all.
								<br /><br />
								The journey had not been easy and there was certainly a lot more to come…
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={warriorz} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - Elrond Warriorz" 
						/>
						<p className="tag caption">Guardian</p>
						<p className="tag caption">Trooper</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Armor: +20</p>
						<p>Fire Rate: +10</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://www.elrondwarriorz.com/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://mobile.twitter.com/elrondwarriorz" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>3. Elrond Warriorz - The fight is not over</h3>

						<ReadMore content={
							<p>
								A long time ago, there weren&#39;t any tribes. There was one territory, and humans all lived together in community, helping and caring for each other. 
								<br /><br />
								Over time, as humans grew in numbers, struggle started to surface in the peaceful community. People started to argue over land, leadership, and scarce raw materials. Lies, treason and violence - features previously unknown to humans - had suddenly morphed into tools for personal success. A new era had begun – the Age of Conflict. 
								<br /><br />
								In the early days of the Conflict, individuals fought each other bare handed, causing limited harm to each other. But it did not take long for humans to realise that to grow stronger, they needed to form groups: Leaders stepped up, Tribes were formed, and arms and weaponry were introduced. The first leaders became Warlords, and they were known to be the strongest and most furious within their Tribes.
								<br /><br />
								It so happened that years of war led to profound division among Tribes. Thousands of Warriors have died in the Conflict, but the Tribes are still standing their ground, fighting until the bitter end.
								<br /><br />
								And yet, there is one group that stood mysteriously aloof, not picking sides but choosing their own path instead - and always responding with striking rage when provoked: 19 outstanding individuals, each with astonishing intelligence, unprecedented strength and unique gifts. Those who fear them, might just call them misfits or outcasts. But those who respect them, created a new name: Tribe X.
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={plata} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - Plata" 
						/>
						<p className="tag caption">Raider</p>
						<p className="tag caption">Mechanic</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Evasion: +12</p>
						<p>Repair: +12</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://plata.network/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/platanetwork" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>4. Plata - Racing our way</h3>

						<ReadMore content={
							<p>
								Planet Nabuu is known for one thing. Everybody will always remember where the first space racing event took place. The adrenaline was out of the ordinary. The best pilots on the planet decided to bring their starships and create a fun way to practise their passion for flying. These were pilots that only dreamed about this. They loved to learn to control their spaceships, they knew everything about their engines inside-out, because they built them.
								<br /><br />
								The news started to be spread among other planets and galaxies as well. News that the people on planet Nabuu started to organise space races. This was crazy! People were having so much fun and it brought so much happiness among them. At last there was some way people could forget about wars, destruction and the whole madness usually happening in people&apos;s life and the universe.
								<br /><br />
								But it wasn&apos;t too long… Not too long until species from other planets wanted to participate as well in the racing events. But why racing? Only for fun? They wanted prizes and fame as well. The ego started to show up in most of the pilots. They started to customise their starships so they can win more often. Each species had its favourite pilots and some didn&apos;t even care anymore if they harm others in the racing events. Someone had to lead in a better way.
							</p>
						} readMore={
							<p>
								Years passed, and the people on planet Nabuu wanted to do something more. These were the pilots that created the very first concept of space racing. For them, it was not about power, not about fame or prizes. It was all about passion. And you could see that in each and every racing event.
								<br /><br />
								As they wanted to teach others their ways and values as well, this is how Plata Academy was created. Made to train the best pilots from the age of thirteen, to become excellent at flying their starships and teach them every little secret they knew to be the best. All skills were tested. Evasion, speed, accuracy, attack abilities. Patience and balance to properly control their ships and become a unity. They even had to simulate raiding through asteroid belts, as space racing became more and more popular, so spectators were demanding for more challenging and interesting races for the advanced teams.
								<br /><br />
								But making the pilots driven and skilled was not enough. Luckily the people from Nabuu were advancing fast. Discovering new technologies and innovations unseen before. With the use of AI and strong planet resources, they developed one of the most powerful fleets of starships. 
								<br /><br />
								The elite group of Plata space racing pilots are now known throughout all galaxies. Always a passionate racer at heart, they inspired so many species to take this path and become skilled pilots. 
								<br /><br />
								Read the entire Drivers Story and dive into the adventures of the racers as it unfolds from the Forgotten Section. <a href="https://plata.network/story/" target="_blank" rel="noreferrer">https://plata.network/story/</a>
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={cybers} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - Neon Cybers" 
						/>
						<p className="tag caption">Trader</p>
						<p className="tag caption">Engineer</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Cargo: +20</p>
						<p>Radar: +3000</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://cybers.tech/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/CybersInAction" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>5. Neon Cybers - Transversing the void of space</h3>

						<ReadMore content={
							<p>
								In an alternate future, humanity has found refuge among the stars. At least those that managed to get on a spaceship and leave the surface of our world.<br />
								From the journal of Jack Lovelace
								<br /><br />
								&quot;Travelers of all ages and beliefs hurried to the crowded, make-shift spaceports in a race to get their hands on one of the seats. The story was the same as always: too many people, too few resources to satisfy everybody... But not of us, common-folk as we were &apos;designated&apos; manage to catch a seat on any of the smaller. privately-operated rockets.
								<br /><br />
								I still remember that rainy and foggy day of early spring huddled with hundreds of other people, in a bid to board the &apos;Magna Ancora&apos;- the Great Refuge or Great Hope. I suppose it&apos;s just a matter of one&apos;s perspective.
								A ship destined to be the new home of twenty-five thousand souls... Sounds impressive when you say it like that but one has to keep in mind there were probably around a hundred thousand of us waiting to board it.
								Now, as I&apos;m writing these lines, starlight shoots past the rounded window of my cabin in colored steaks, like in a psychedelic dream.
								So, I suppose I should be thankful for being counted among the lucky few that managed to find passage.
							</p>
						} readMore={
							<p>
								That is, unless this is a dream and I am still huddled in a tent, pitched between the mud pools, grasping at straws to find salvation.
								<br /><br />
								[...]
								<br /><br />
								It&apos;s been months or maybe even years since we left the homeworld. Time feels differently in space. When we first took off, we did not know what to expect. But we are survivors and we learned to adapt. Never did I think it would come to this, but we forged implants and chips and had to alter our bodies, to prepare ourselves not only for the rigors of space travels but for ... the Encounters.
								<br /><br />
								[...]
								<br /><br />
								Yeah, I guess we didn&apos;t expect to encounter alien species so soon in our travels. And, at any rate, we only had a romanticized idea, hoping to find some benevolent sages that would share wisdom and knowledge far superior to our own. We were wrong... almost dead wrong!
								<br /><br />
								Soon after we left our own solar system we encountered those that we&apos;ve come to call The Marauders. They travel from system to system, preying on others, stealing, destroying, and kidnapping. They boarded our ship in an instant. Hell-bent on taking whatever they wanted from us. But we fought back. And we had to adapt and evolve again. The boarding party we managed to overcome by sheer force of numbers. And luckily we were able to salvage their weapons.
								<br /><br />
								Five more raiding parties then beamed across our ship. Many of us dies, but by stealing their weapons we defended ourselves. And after those five raids, they just stopped and whizzed by, probably to hunt another target. Maybe they got what they wanted. Maybe our opposition was too fierce and their casualties too great. Maybe we had nothing of value for how much trouble we were.
								They left as quickly as they came.
								<br /><br />
								But their weapons remain in our hands and I fear that in the absence of an enemy, they may bring out the worst in us. I fear we might turn on each other.
								<br /><br />
								[...]
								<br /><br />
								We keep drifting through space. Fuel is yet no problem for the &apos;Magna Ancora&apos;. Outside there is only a sheer vast beauty, and the darkness, though seemingly void at first - who knows what wonders and riches it may hide?&quot;
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={zoidpay} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - ZoidPay" 
						/>
						<p className="tag caption">Visionary</p>
						<p className="tag caption">Tactical Officer</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Health: +250</p>
						<p>Accuracy: +10</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://zoidpay.com" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/ZoidPay" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>6. The Zoidsters - Rise of the shapeshifters</h3>

						<ReadMore content={
							<p>
								The Caliber is a band of humanoids that stand at over twenty meters tall. They are organic beings combined with precious metals, including metals that can only be synthesized in the Caliber and are critical to the technology of some of the other factions. They tend to inhabit the asteroids and outer reaches of star systems where other beings find it inhospitable. The Caliber have been hunted for generations as the other factions have sought a steady supply of the metals that compose them. While preferring peace, they have been forced to convert to a warrior culture. Being hunted for such long time, they were constantly travelling in the hope that one day they will find a place where nobody could find them anymore.
								<br /><br />
								While travelling with their spaceships they suddenly saw on their maps a new planet in their way. It could not be picked up by the radars from the distance, so they initially thought there&apos;s nothing there. The planet itself had a very different appearance. A wave of yellow dust was surrounding it like it almost looked toxic. They were a bit sceptical at first so they initially sent an exploration group.
								<br /><br />
								Landing down on the planet, everything looked like an entire slum. It was full of imense green vegetation, with volcanos and green and yellow smoke coming out from the slums.
								<br /><br />
							</p>
						} readMore={
							<p>
								They suddenly felt dizzy, like charmes were being done to them and something was luring them into the slums. So much that looked like something was controlling them, so that&apos;s what they did. They entered willingly in the smoke of the slums and you could see them laying down. How could this happen? What kind of planet was this or was there some creature controlling them?
								<br /><br />
								Meanwhile, on the Caliber spaceship, they got quite worried. No one was responding anymore, so they had to send another crew to investigate and perhaps save the others. 
								The second crew went down to search the others. They looked around and they were surprised by the look of the planet. One of them suddenly saw a small insect. He looked at it to admire it as it looked so colourful and interesting. But when he wanted to come closer and take it, the insect suddenly changed form into a lizard. He got insanely scared. What was that? How could insects here could change form like this? This was insane. And where was the other crew that disappeared? 
								<br /><br />
								They turned around as they heard some noise in the green jungle. Weapons prepared and they went toward the noise when suddenly 4 different creatures came out from behind the trees. “Don&apos;t shoot, don&apos;t shoot, it&apos;s us!!” - they said.
								<br /><br />
								How could it be them? They looked so different. “This planet has some weird toxic substance. We&apos;re not sure what is it, but our mind started instantly to feel under control of something else and was urging us to go into the slum. We just all woke up looking different and we can even change into something else.”
								<br /><br />
								“I knew this planet is dangerous, we never should&apos;ve come. We also noticed how an insect took the form of a lizard in from of our eyes. What kind of spell is this?”
								<br /><br />
								“Not sure if it&apos;s a spell, must be definitely something with this green smoke around, maybe it changes your DNA.”
								<br /><br />
								“Wait a minute. This could be our salvation. If we can transform your appearance in anything you want, they can never hunt us anymore. No one could ever find us again.”
								<br /><br />
								And so they did, but not before taking some more samples from the planet to study them and make sure it’s safe for them to shape shift. This could be the chance of their life and the salvation of their people. They need to keep this a secret.
								<br /><br />
								They asked the mothership to come down so they could transform them all. They even decided to take a new name, to celebrate the reborn. They called themselves The Zoidsters. Since then, they can finally be free and travel anywhere. They may look friendly. But you don’t know who they actually are now. Sometimes they decide to take monstrous forms and scare other species. Sometimes they can be innocent and quite funny and hilarious. They blend in perfectly. No one could track them down and they can live freely among the factions.
								<br /><br />
								Could you defeat a Zoidster?
								<br /><br />
								Beware. They are trickster gods who can shapeshift now. 
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={aerovek} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - Aerovek" 
						/>
						<p className="tag caption">Bounty Hunter</p>
						<p className="tag caption">Flight Pilot</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Attack: +12</p>
						<p>Extra Crew: +20</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://www.aerovek.io/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/AeroFoundation" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>7. Aerovek - Tales of the bounty hunters</h3>

						<ReadMore content={
							<p>
								Colonies along the edges of the faction territories have been having their children abducted by unknown enemies hiding beyond faction controlled territory. Nobody knew why this is happening or who is taking them. The people of these colonies put together their resources and started to scour the stars, searching for clues and signs of their kids, as well as defending the outer colonies against this threat. Most, if not all, started small. One ship and a few fathers and mothers. Eventually, even the wealthy and politicians began to fear, and started to fund their own militia groups. Hundreds of separate groups banded together, forming information highways. But still they got no luck in finding the missing ones. Maybe they didn&apos;t have the necessary skills, they didn&apos;t know where to look and how to trace them.
								<br /><br />
								As the time passed, they needed another solution. They needed more help, and they were not trained enough to find all the answers. 
								<br /><br />
							</p>
						} readMore={
							<p>
								Hopefully, from the Aero planet, a new group of heroes came into action. They were the only hope right now to find the missing kids. The Aerovek organisation is known to have the best pilots and bounty hunters in this galaxy. Thousands of people will be forever grateful for their species, as they saved so many lives and solved so many mystery cases along the years. They are tall beings, sharp, agile and extremely fast moving. They have unbelievable fighting techniques and their feathers are like blades. They can catch you in a blink of an eye and they can go undetected fairly easily. Their spaceships are really small and sharp as well, being extremely fast in chasing the enemies.
								<br /><br />
								While doing a hunting mission, one of them got in touch with the missing kids case. A group of parents begged them to get involved and saved their kids. They had to do the thing nobody succeeded until now. And so they did.
								<br /><br />
								After a while, they started discovering some of the hard evidence of the children&apos;s whereabouts. And started finding them. Some of the children are adults now, some grew to old age and died, having been enslaved their entire lives. Other strange species are also to be found bound and tied to chains. All of the enslaved seem to be mining a specific resource within massive asteroid like structures in deep space. Who, or what creature is looking for that resource and what is using it for, is a mystery. But maybe not for long… The Aerovek hunters are here!
							</p>
						} />
					</Col>
				</Row>
			</Col>

			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={srb} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - Super rare bears" 
						/>
						<p className="tag caption">Warden</p>
						<p className="tag caption">Defence Officer</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Health: +250</p>
						<p>Armor: +20</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://superrarebears.com" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/SuperRare_Bears" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>8. Super Rare Bears - The Kroganverse Guardians</h3>

						<ReadMore content={
							<p>
								In the vast expanse of the universe, the Super Rare Bears were born not as they are now, but as tiny sparks of cosmic consciousness. 
								<br /><br />
								Their journey began when a nebula known as the Stellar Nursery erupted into a supernova, scattering them across the cosmos. 🌌🐻
								<br /><br />
								As these sparks traveled, they absorbed cosmic energies and the wisdom of the stars.
								<br /><br />
								Over eons, they evolved and transformed, taking on physical forms that were as diverse and unique as the universe itself. Each bear reflected a piece of the cosmos. 🌠
								<br /><br />
							</p>
						} readMore={
							<p>
								Some donned shimmering coats of stars, others held the depth of black holes in their eyes, and yet others radiated auras akin to nebulous clouds.
								<br />
								But despite their astonishing transformation, they were wanderers without a home. 🌟
								<br /><br />
								Their cosmic journey led them to the vibrant realm of KroganVerse, a lush world teeming with life. 
								<br />
								Drawn to its vitality, the Super Rare Bears descended, finding the sanctuary they had long sought. But, the realm was a place of struggle and contention. 🌍
								<br /><br />
								They saw species locked in relentless battles for survival, a stark contrast to the harmonious unity they had known in the cosmos. 
								<br />
								This discord disturbed the peace-loving Super Rare Bears, who decided to bring tranquility to this turmoil. ☮️
								<br /><br />
								Each bear used its unique attributes to benefit the realm. 
								<br />
								The starlit bears guided creatures through the night, those with black hole eyes absorbed negative energies, and the nebulous aura bears shared their cosmic wisdom, fostering understanding. 🌠
								<br /><br />
								Over time, the KroganVerse was transformed. Discord gave way to harmony. 
								<br />
								The Super Rare Bears did not rule; instead, they guided the creatures towards a peaceful coexistence, embodying the cosmic principle of unity in diversity. 🌍
								<br /><br />
								Today, they stand not just as inhabitants, but as guardians of the KroganVerse. 
								<br />
								Each bear, a testament to the diversity and potential of the cosmos, symbolizes the enduring journey from a spark of consciousness to a creature of cosmic wisdom. 🐻
								<br /><br />
								The tale of the Super Rare Bears is a reflection of the universe itself: a story of evolution, transformation, and finding a place to call home amidst diversity. 
								<br />
								A testament to peace, wisdom, and thriving in a world teeming with life.
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={pittz} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - CryptoPittz" 
						/>
						<p className="tag caption">Innovator</p>
						<p className="tag caption">Tactical Officer</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Accuracy: +10</p>
						<p>Cargo: +20</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://www.cryptopittz.io/" target="_blank" rel="noreferrer">Website</a><br />
						<a href="https://twitter.com/CryptoPittz" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>9. CryptoPittz - Always up for some interstellar fun!</h3>

						<ReadMore content={
							<p>
								In an alternate universe, where streets echo with the carefree laughter of anapomorphic dogs, the CryptoPittz were born. These extraordinary creatures emerged from the most unlikely of sources: a secretive science experiment designed to concoct the ultimate alpha male. This experiment unexpectedly combined elements from various animal`s DNA, the seeds of a vibrant cannabis strain, and a stray hair from Lou, the chief scientist`s lovably stoner dog. The result was a comically accidental yet marvelous creation: the CryptoPittz.
								<br /><br />
								These intriguing beings, endowed with unmatched charisma and sporting looks that would put movie stars to shame, began to outshine and outperform mankind. They became an embodiment of eternal hedonia, a spectacle of endless parties and technological creations. From the chaos and fun, an economy emerged, based on the trade and sale of $BONEZ, the CryptoPittz`s favorite form of currency.
								<br /><br />
								Then, in a comically absurd turn of events, a group of absent-minded CryptoPittz, trying to develop a cannabis strain capable of levitating spacecraft, accidentally created a rip in the dimensional time warp continuum. This tear in space-time resulted in a wormhole, enabling interdimensional travel. 
								<br /><br />
								Hence, the party-animal CryptoPittz turned into galactic explorers, voyaging across different metaverses. This was the beginning of the interdimensional, intergalactic, and inter-buds journey of the CryptoPittz. From parties on earth to adventures across dimensions, the CryptoPittz live a life filled with limitless excitement and fun!
							</p>
						}/>
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container">
				<Row>
					<Col sm={3} xl={2} className="thumbnail mb-2">
						<img 
							src={ducks} 
							className="img-fluid round-corners" 
							alt="Krogan partnership - The Ducks" 
						/>
						<p className="tag caption">Scientist</p>
						<p className="tag caption">Science Officer</p>
						<p className="tag caption">Species</p>
						<p className='mt-2'>Acceleration: +15</p>
						<p>Crew: +20</p>

						<p className="mt-2"><strong>Official pages</strong></p>
						<a href="https://twitter.com/ElrondDucks" target="_blank" rel="noreferrer">Twitter</a>
					</Col>
					<Col sm={9} xl={10} className="content">
						<h3>10. Elrond Ducks - The Quackronauts</h3>

						<ReadMore content={
							<p>
								Legend has it that in a galaxy far, far away, there existed a flock of ducks like no other. They were known as The Quackronauts, and their deeds would be told and retold for generations to come.
								<br /><br />
								These brave ducks were not content to stay in their own corner of the universe. Instead, they set out to explore the multiverse, to seek out new worlds and new civilizations, and to boldly go where no duck had gone before.
								<br /><br />
								Their journey was not an easy one. They faced dangers at every turn, from interdimensional monsters to treacherous black holes. But through it all, The Quackronauts persevered, relying on their quackish humor and their unwavering courage to see them through even the darkest of times.
								<br /><br />
								And they were not just explorers, but heroes as well. Whenever a cry for help went out across the stars, The Quackronauts were quick to respond, their ships streaking through the void with blazing engines and quacks ringing out in defiance of whatever obstacles lay in their path.								
							</p>
						} readMore={
							<p>
								<br />
								Their heroism knew no bounds. They risked everything to save other ducks, to help those in need, and to explore the unknown reaches of the multiverse. And even in the face of seemingly insurmountable odds, they never gave up.
								<br /><br />
								Through their hardships and their heroism, The Quackronauts have become living legends. Their exploits continue to inspire new generations of ducks to dream of the stars and to reach for the impossible. And even today, as they continue to explore the multiverse and lend their aid to those in need, you can still hear the echo of their battle cry: &quot;Quack loud enough, and we will be there!&quot; For The Quackronauts are not just heroes of the past, but heroes of the present and future, ready to face any challenge and to overcome any obstacle in their quest for adventure and discovery.
							</p>
						} />
					</Col>
				</Row>
			</Col>
			<Col xs={12} className="box-container coming-soon">
				<p className="center"><strong>Your project here</strong></p>
			</Col>
		</Row>
	);
}

export default PartnerSection;