import React, { useEffect } from 'react'
import { contractViews } from 'contracts/ContractViews';
import { DelegationTransactionType, Denominate, delay, nominateNumberToHex, nominateStringToHex, timeLeftToShortString, useAccount, useCoreContext, useTransactions } from 'core';
import { LineButton } from '../uielements/LineButton';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEvents';
import { kroTicker, nftAttributesContract, pointsPrice } from 'config';

export const PurchasePoints = ({
	points,
	reload,
}: {
	points: number
	reload: () => void
}): React.ReactElement => {

	const { proxy } = useAccount()
	const { address } = useCoreContext()
	const { sessionId, sendTransaction } = useTransactions()
	const gaEventTracker = useAnalyticsEventTracker('Upgrades')
	
	const [lastPurchase, setLastPurchase] = React.useState(0)

	const init = () => {
		reload()
		loadLastPurchase()
	}

	const loadLastPurchase = () => {
		contractViews.getLastPointsPurchase(proxy(), address)
			.then(value => {
				if (value) setLastPurchase(value)
			})
	}
	useEffect(loadLastPurchase, [address])

	const purchase = (points: number) => {
		const args = []
		args.push(nominateStringToHex(kroTicker))
		args.push(nominateNumberToHex(pointsPrice[points.toString()]))
		args.push(nominateStringToHex('buyPoints'))
		args.push(nominateNumberToHex('' + points))

		const data = args.join('@')
		const txArguments = new DelegationTransactionType(nftAttributesContract, '0', 'ESDTTransfer', data)
		sendTransaction([txArguments], 'purchasePoints', 'Purchase Points', () => delay(2000).then(init))

		gaEventTracker(`Buy-Points-${points}`)
	}

	const purchaseNeedsWaiting = () => {
		return lastPurchase > 0 && lastPurchase < Date.now() / 1000 + 5184000
	}

	return (
		<div className='p-2 mb-4'>

			<div className="d-flex align-items-center justify-content-center">
				<div className='bordered p-2'>
					<h4>Basic</h4>
					<p>10 points</p>
					<Denominate value={pointsPrice['10']} token={kroTicker} decimals={2} />
					<LineButton 
						className="my-2" 
						small 
						sessionId={sessionId} 
						onClick={() => purchase(10)} 
						disabled={purchaseNeedsWaiting()}
					>
						Purchase
					</LineButton>
				</div>
				<div className='bordered p-md-4 p-3 m-1'>
					<h4>Advanced</h4>
					<p>25 points</p>
					<Denominate value={pointsPrice['25']} token={kroTicker} decimals={2} />
					<LineButton 
						className="my-2" 
						small 
						sessionId={sessionId} 
						onClick={() => purchase(25)}
						disabled={purchaseNeedsWaiting()}
					>
						Purchase
					</LineButton>
				</div>
				<div className='bordered p-2'>
					<h4>Pro</h4>
					<p>50 points</p>
					<Denominate value={pointsPrice['50']} token={kroTicker} decimals={2} />
					<LineButton 
						className="my-2" 
						small 
						sessionId={sessionId} 
						onClick={() => purchase(50)}
						disabled={purchaseNeedsWaiting()}
					>
						Purchase
					</LineButton>
				</div>
			</div>

			{purchaseNeedsWaiting() && 
				<p className='center colored text-strong'>Additional points can be purchased in {timeLeftToShortString(lastPurchase + 5184000 - Date.now() / 1000)}</p>
			}

			<p className='center'>
				If you wish to <span className='colored'>accelerate</span> the process, you can purchase additional points. 
				These points can be used on <span className='colored'>any spaceship</span>.
			</p>
			<p className='center text-strong'>Available: {points} extra points</p>
		</div>
	);
};
