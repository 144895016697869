import React from 'react'
import { Col, Row } from 'react-bootstrap';

import reversedTriangle from 'images/header-triangle.svg'
import arrowDown from 'images/arrow-down.png'
import Menu, { MenuType } from 'views/components/Menu';
import TimerView from 'views/components/TimerView';

import video from 'images/video-banner.jpg'
import VideoModal from 'views/components/Others/VideoModal';
import TokenPrice from 'views/components/Others/TokenPrice';
import Exchange from 'views/components/Others/Exchange';
import { LineButton } from 'views/components/uielements/LineButton';

const MainSection = (): React.ReactElement => {

	const [showVideoModal, setShowVideoModal] = React.useState(false);

	const scrollToFeatures = () => {
		document.querySelector('#nft-mint-header')?.scrollIntoView({ 
			behavior: 'smooth' 
		});
	}

	return (
		<>
		<Row className="main">
			<Menu menuType={MenuType.Complete} />
			<Col md={6} className="header">

				<div className="left-side main-content">
					<h1>Krogan Ecosystem</h1>
					<p>
						Krogan ecosystem is built around the interstellar Play to Earn game.
						<br />
						Be part of one of the 10 factions and join the fight for supremacy!
					</p>
					<TokenPrice />
				</div>

				<div id="middle-side">
					<img src={reversedTriangle} alt="Krogan Minting" className="mb-4" />
					<p className="h2">Enter Kroganverse</p>
					<p className="text-action mb-2">BETA</p>
					<LineButton url="https://kroganverse.com" blankPage className='me-3'>Play Game</LineButton>
					
					<div className="scroller">
						<a onClick={scrollToFeatures}><img src={arrowDown} alt="Learn more about Krogan Coin" /></a>
					</div>
				</div>

				<div className="left-section mb-4">
					<div className="box">
						<h3>How it all started?</h3>
						<p className="mb-2">This is how various species were formed in the Kroganverse.</p>
						<img 
							src={video} 
							alt="How Krogan started" 
							className="img-fluid video-banner clickable"  
							onClick={() => setShowVideoModal(true)}
						/>
					</div>
				</div>

			</Col>

			<Col md={6} className="right-side d-flex flex-column justify-content-end">

				<div className="right-section second-half mb-4">
					<div className="box">
						<h3>Earn while you play</h3>
						<p>The game features spaceship NFTs of various levels and abilities. Using these spaceships, you can discover the Kroganverse, mine, evolve, colonize, destroy and much more. All these actions have 1 thing in common. Finding more of the scarce resource KRO, the official token of the ecosystem.</p>
					</div>
				</div>
				<div className="right-section second-half">
					<LineButton className="me-5" type="secondary" url="https://whitepaper.krogancoin.com" blankPage>WHITEPAPER</LineButton>
				</div>
			</Col>
		</Row>

		<VideoModal show={showVideoModal} handleClose={() => setShowVideoModal(false)} />
		</>
	);
}

export default MainSection;