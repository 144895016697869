import BigNumber from 'bignumber.js';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { 
	Address, ContractFunction, Query, ResultsParser, decodeUnsignedNumber, 
	decodeBigNumber, U32Value, AddressValue, StringValue, BigUIntValue, BinaryCodec, ArrayVecType, U32Type, ArrayVec, decodeBool 
} from '@multiversx/sdk-core';
import { CategoryType } from 'utils/types';
import { nftAttributesContract } from 'config';

export const contractViews = {
	nftFixed: (proxy: ProxyNetworkProvider, nonce: number): Promise<boolean> => {
		const query = new Query({
			address: new Address(nftAttributesContract),
			func: new ContractFunction('getNftCorrectUpgrades'),
			args: [new U32Value(nonce)],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			return decodeBool(bundle.values.first())
		});
	},
	allUpgrades: (proxy: ProxyNetworkProvider, nonce: number): Promise<number[]> => {
		const query = new Query({
			address: new Address(nftAttributesContract),
			func: new ContractFunction('allUpgrades'),
			args: [new U32Value(nonce)],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			const categories: number[] = []
			const codec = new BinaryCodec()
			const decoded = codec.decodeTopLevel<ArrayVec>(bundle.values.first(), new ArrayVecType(10, new U32Type()))
			decoded.getItems().forEach(item => {
				categories.push(item.valueOf().toNumber())
			})
			return categories
		});
	},
	getPoints: (proxy: ProxyNetworkProvider, nonce: number): Promise<number> => {
		const query = new Query({
			address: new Address(nftAttributesContract),
			func: new ContractFunction('points'),
			args: [new U32Value(nonce)],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			return decodeBigNumber(bundle.values.first()).toNumber()
		});
	},
	getLastPointsPurchase: (proxy: ProxyNetworkProvider, address: string): Promise<number> => {
		const query = new Query({
			address: new Address(nftAttributesContract),
			func: new ContractFunction('getLastPurchased'),
			args: [new AddressValue(new Address(address))],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			return decodeBigNumber(bundle.values.first()).toNumber()
		});
	},
	getPurchasedPoints: (proxy: ProxyNetworkProvider, address: string): Promise<number> => {
		const query = new Query({
			address: new Address(nftAttributesContract),
			func: new ContractFunction('getPurchasedPoints'),
			args: [new AddressValue(new Address(address))],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			return decodeBigNumber(bundle.values.first()).toNumber()
		});
	},
	getCategory: (proxy: ProxyNetworkProvider, category: number, delegationContract?: string): Promise<CategoryType> => {
		const query = new Query({
			address: new Address(delegationContract),
			func: new ContractFunction('getCategory'),
			args: [new U32Value(category)],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			return {
				quantity: decodeUnsignedNumber(bundle.values[0]),
				level: decodeUnsignedNumber(bundle.values[1]),
				price: decodeBigNumber(bundle.values[2]).toString()
			}
		});
	},
	getAllCategories: (proxy: ProxyNetworkProvider, delegationContract?: string): Promise<CategoryType[]> => {
		const query = new Query({
			address: new Address(delegationContract),
			func: new ContractFunction('getAllCategories'),
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			let categories = []
			for (let i = 0; i < bundle.values.length/3; i++) {
				let start = i*3
				let category: CategoryType = {
					quantity: decodeUnsignedNumber(bundle.values[start]),
					level: decodeUnsignedNumber(bundle.values[start+1]),
					price: decodeBigNumber(bundle.values[start+2]).toString()
				}	
				categories.push(category)
			}
			return categories
		});
	},
	getAmountOut: async (proxy: ProxyNetworkProvider, contract: string, tokenIn: string, amountIn: string): Promise<BigNumber> => {
		console.log(`Query ${tokenIn} ${amountIn}`)
		if (amountIn == '0') return new BigNumber('0')
		const query = new Query({
			address: new Address(contract),
			func: new ContractFunction('getAmountOut'),
			args: [
				new StringValue(tokenIn),
				new BigUIntValue(new BigNumber(amountIn))
			],
		});
		return proxy.queryContract(query).then(value => {
			const resultsParser = new ResultsParser()
			const bundle = resultsParser.parseUntypedQueryResponse(value)
			return decodeBigNumber(bundle.values.first())
		});
	}
};
