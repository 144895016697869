import React, { useEffect, useState } from 'react';
import { AccountType } from '@multiversx/sdk-dapp/types'
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks'
import { logout } from '@multiversx/sdk-dapp/utils'
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { useCoreContext, useCoreDispatch } from 'core/context';
import { coreAccountService } from 'core/services/account.service';
import { network } from 'config';

export function useAccount() {
	
	const dispatch = useCoreDispatch()
	const isLoggedIn = useGetIsLoggedIn()
	const { address, account, isAccountLoading } = useGetAccountInfo()
	const { kroTicker, followTokens } = useCoreContext()

	const [inProgressTx, setInProgressTx] = useState({} as { [key: string]: (success: boolean) => void })

	const refreshTokenList = () => {
		if (!isLoggedIn) return
		coreAccountService.fetchAccountTokens(address, kroTicker).then(values => {
			dispatch({ type: 'setKroToken', token: values?.first() });
		})

		if (followTokens.length == 0) return
		coreAccountService.fetchAccountMultiTokens(address, followTokens)
			.then(values => {
				dispatch({ type: 'setGeneralTokens', tokens: values })
			})
	}

	const updateAccount = () => {
		setLocalContext(account)
	}
	useEffect(updateAccount, [isAccountLoading, account])

	const disconnect = () => {
		console.log('[Connection] Logout')
		setLocalContext(undefined)
		logout()
	}

	const setLocalContext = (account: AccountType | undefined) => {
		console.log('[Connection] Local context updated')
		if (isLoggedIn && account && account.address != '') {
			dispatch({ type: 'setAccount', account: account })
			dispatch({ type: 'setAddress', address: account.address })
			dispatch({ type: 'setIsLoggedIn', isLoggedIn: true })
		} else {
			dispatch({ type: 'setAccount', account: undefined })
			dispatch({ type: 'setAddress', address: '' })
			dispatch({ type: 'setIsLoggedIn', isLoggedIn: false })
		}
	}

	const addTx = (key: string, func: (success: boolean) => void) => {
		setInProgressTx({
			...inProgressTx,
			[key]: func,
		})
	}

	const removeTx = (key: string) => {
		setInProgressTx(current => {
			const copy = {...current}
			delete copy[key]
			return copy
		})
	}

	const proxy = () => {
		return new ProxyNetworkProvider(network.apiAddress, { timeout: 6000 })
	}

	return {
		addTx,
		removeTx,
		inProgressTx,
		disconnect,
		refreshTokenList,
		proxy,
		isLoggedIn
	}
}