import React from 'react'
import { Col, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';

export enum MenuType {
	Complete = 1,
	Other = 2,
	Empty = 3,
}

const Menu = ({
	menuType
}: {
	menuType: MenuType
}): React.ReactElement => {

	const menuForType = () => {
		switch (menuType) {
			case (MenuType.Complete):
				return (
					<>
						<Nav className='ms-auto'>
							<Nav.Link href="#roadmap" className="text-action px-2">Roadmap</Nav.Link>
							<Nav.Link href="#discord" className="text-action px-2">Discord</Nav.Link>
							<Nav.Link href="/partners" className="text-action px-2">Partners</Nav.Link>
							<NavDropdown title="Platforms" id="platforms-dropdown" className="text-action px-2">
								<NavDropdown.Item href="https://kroganswap.com" target="_blank">Marketplace</NavDropdown.Item>
								<NavDropdown.Item href="https://kroganverse.com" target="_blank">Metaverse</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="KRO Token" id="kro-dropdown" className="text-action px-2">
								<NavDropdown.Item href="/staking">KRO Staking</NavDropdown.Item>
								<NavDropdown.Item href="/swap">Exchange</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Krogan NFT" id="kro-dropdown" className="text-action px-2">
								<NavDropdown.Item href="/nft-staking">NFT Staking</NavDropdown.Item>
								<NavDropdown.Item href="/fleet">My NFTs</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</>
				)
			case (MenuType.Other):
				return (
					<>
						<Nav className="ms-auto">
							<Nav.Link href="/" className="text-action px-2">Home</Nav.Link>
							<Nav.Link href="/partners" className="text-action px-2">Partners</Nav.Link>
							<NavDropdown title="Platforms" id="platforms-dropdown" className="text-action px-2">
								<NavDropdown.Item href="https://kroganswap.com" target="_blank">Marketplace</NavDropdown.Item>
								<NavDropdown.Item href="https://kroganverse.com" target="_blank">Metaverse</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="KRO Token" id="kro-dropdown" className="text-action px-2">
								<NavDropdown.Item href="/staking">Staking</NavDropdown.Item>
								<NavDropdown.Item href="/swap">Exchange</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Krogan NFT" id="kro-dropdown" className="text-action px-2">
								<NavDropdown.Item href="/nft-staking">NFT Staking</NavDropdown.Item>
								<NavDropdown.Item href="/fleet">My NFTs</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</>
				)
			default:
				return (<></>)

		}
	}

	return (
		<div id="navigation" className="menu">
			<Navbar collapseOnSelect expand="lg" variant="dark">
				<Navbar.Brand href="/">
					<div id="logo">
						<img src="/images/logo.svg" width="120" alt="Krogan Coin" />
					</div>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					{menuForType()}
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default Menu;